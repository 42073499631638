import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'

import { Row, Col, Progress, Drawer, Tag, Checkbox } from 'antd'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { useAuth0 } from '@auth0/auth0-react'

import { useLocale, useDateFormat } from 'stores/UserStore'
import {
    useView,
    useSetView,
    useContent,
    useSetContent,
    useSetDaysInView,
    useSetAvalancheProblems,
    useSetCharacterLimits,
} from 'stores/ForecastStore'
import { API_SUCCESS_CODES, AVALANCHE_SUMMARY_VIEW, SNOWPACK_SUMMARY_VIEW, WEATHER_SUMMARY_VIEW } from 'utils/Constants'
import { getColourOfReviewStatus, getReviewStatusTextColour } from 'utils/ForecastContent'
import { TwoColLayoutPrimaryRight } from 'components/TwoColumnLayout/TwoColLayoutPrimaryRight'
import { Stat } from 'components/Stat/Stat'
import { MenuItem } from 'components/MenuItem/MenuItem'
import { Summary } from 'components/AvalancheForecast/Content/Summary'
import { AvalancheProblems } from 'components/AvalancheForecast/Content/AvalancheProblems/AvalancheProblems'
import { DangerRatings } from 'components/AvalancheForecast/Content/DangerRatings'
import { HighwayHazard } from 'components/AvalancheForecast/Content/HighwayHazard'
import { Communications } from 'components/AvalancheForecast/Content/Communications'
import { Confidence } from 'components/AvalancheForecast/Content/Confidence/Confidence'
import { Media } from 'components/AvalancheForecast/Content/Media'
import { Review } from 'components/AvalancheForecast/Content/Review/Review'
import { ReviewNotesEditor } from 'components/AvalancheForecast/Content/Review/ReviewNotesEditor'
import { HwyFxAvalancheProblems } from 'components/AvalancheForecast/Content/AvalancheProblems/HwyFxAvalancheProblems'
import messages from 'services/intl/messageDefinitions'
import { fetchSettings } from 'services/Settings'
import { Select } from 'components/Dropdown/Select'
import { Assistant } from 'components/Assistant/Assistant'

export const Forecast = ({ progressToPercent, menuItems, fetchForecast, putForecast, compressed, colWidth }) => {
    // env vars
    const base = process.env.REACT_APP_PREVIEW_BASE_URL
    const apiUrl = process.env.REACT_APP_API_URL
    const apiKey = process.env.REACT_APP_API_KEY

    // React hooks
    const [queryParams, setQueryParams] = useSearchParams()
    const { pathname } = useLocation()
    const [layoutFraction, setLayout] = useState(18)
    const [dayDetails, setDayDetails] = useState([])
    const [reviewDrawerVisible, setReviewDrawerVisible] = useState(false)
    const didMount = useRef(false)
    const { getAccessTokenSilently } = useAuth0()

    // User Store
    const locale = useLocale()
    const dateFormat = useDateFormat()

    // Forecast Store
    const content = useContent()
    const setContent = useSetContent()
    const view = useView()
    const setView = useSetView()
    const setDaysInView = useSetDaysInView()
    const setAvalancheProblems = useSetAvalancheProblems()
    const forecastID = queryParams.get('focus')
    const setCharacterLimits = useSetCharacterLimits()

    const progressComplete = content.progress?.every((el) => el.complete)

    const getDayDetails = (content) => {
        if (!content.forecastDays) {
            return []
        }

        return content.forecastDays.map((day, i) => {
            return {
                position: i,
                date: moment(day.date).format(dateFormat),
                day: moment(day.date).format('dddd'),
            }
        })
    }

    const setDaysInViewFromQueryParams = (daysQuery) => {
        const daysArray = daysQuery.split('').map(Number)
        let daysInView = {
            1: false,
            2: false,
            3: false,
            4: false,
        }
        daysArray.forEach((day) => {
            daysInView[day] = true
        })
        setDaysInView(daysInView)
    }

    const preview = useCallback(async () => {
        const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
        })

        window.open(
            `${base}/${locale}/preview?product=${content.id}&apiUrl=${apiUrl}&apiKey=${apiKey}&token=${token}`,
            '_blank'
        )
    }, [getAccessTokenSilently, base, locale, content.id, apiUrl, apiKey])

    const getForecast = useCallback(async (id) => {
        const response = await fetchForecast(id, locale)
        setContent(response.data)
    }, [])

    const updateAvalancheForecast = async (data, locale) => {
        const response = await putForecast(data, locale)
        if (API_SUCCESS_CODES.includes(response.status) && response.data) {
            setContent(response.data)
        } else {
            getForecast(forecastID)
        }
    }

    const getCharacterLimits = useCallback(async () => {
        const response = await fetchSettings(locale)
        if (API_SUCCESS_CODES.includes(response.status) && response.data.characterLimits) {
            setCharacterLimits(response.data.characterLimits)
        }
    })

    useEffect(() => {
        if (forecastID) {
            getForecast(forecastID)
            didMount.current = true
        }

        // set the view based on the query params
        if (queryParams.get('view') && Object.keys(menuItems).includes(queryParams.get('view'))) {
            setView(queryParams.get('view'))
        }

        // Set the days in view based on the query params
        if (queryParams.get('days') && parseInt(queryParams.get('days'))) {
            setDaysInViewFromQueryParams(queryParams.get('days'))
        }
    }, [queryParams])

    useEffect(() => {
        setDayDetails(getDayDetails(content))
    }, [content])

    useEffect(() => {
        if (pathname !== '/archive') {
            document.title = content.name
        }
    }, [content])

    useEffect(() => {
        getCharacterLimits()
    }, [])

    const update = (data) => {
        updateAvalancheForecast(data, locale)
    }

    const handleSectionClick = useCallback((view) => {
        setQueryParams({ focus: queryParams.get('focus'), view })

        // The avalanche Problem section has its own field in the store and on the loading of each section they check
        // that field is empty. This triggers the setDayCards allowing the card to appear.
        // Note this doesn't apply to the highways Forecast
        setAvalancheProblems([])

        // Setting the view
        setView(view)
    })

    const checkAll = useCallback(() => {
        const progress = [...content.progress].map((element) => {
            return { ...element, complete: !progressComplete }
        })
        update({ ...content, progress })
    })

    const header = (
        <div style={styles.header}>
            <Stat label={'forecaster'} value={content.username} type={'text'} size={'small'} />
            <Stat label={'issueDateTime'} value={content.issueDateTime} type={'date'} time={true} size={'small'} />
            <Stat label={'expiryDateTime'} value={content.expiryDateTime} type={'date'} time={true} size={'small'} />
            <Row>
                <Col style={styles.detailCol} span={16}>
                    <Stat label={'dayOne'} value={content.dayOne} size={'small'} type={'text'} />
                </Col>
                <Col style={styles.detailCol} span={8}>
                    <Stat label={'status'} value={content.status} size={'small'} type={'text'} />
                </Col>
            </Row>
            <Stat
                label={'progress'}
                value={<Progress percent={progressToPercent(content.progress)} />}
                size={'small'}
            />
        </div>
    )

    const Menu = []
    const DropDownMenu = []

    for (let key in menuItems) {
        // sets the styling for the selected menu item
        const selected = view === key ? true : false

        const checked = content.progress?.find((element) => element.element === key)?.complete

        // Adds review status badge to the review menu item
        const reviewBadge =
            key === 'review' && content.review?.status ? (
                <Tag
                    color={getColourOfReviewStatus(content.review.status)}
                    style={{ ...styles.tag, color: getReviewStatusTextColour(content.review.status) }}
                >
                    <FormattedMessage {...messages[content.review.status]} />
                </Tag>
            ) : null

        Menu.push(
            <MenuItem
                clickHandler={handleSectionClick}
                view={key}
                key={key}
                selected={selected}
                label={<FormattedMessage {...messages[key]} />}
                checked={checked}
                Badge={reviewBadge}
                update={update}
            />
        )

        DropDownMenu.push(
            <Select.Option key={key} value={key}>
                <FormattedMessage {...messages[key]} />
            </Select.Option>
        )
    }

    const left = (
        <div style={styles.left}>
            {header}
            <div style={styles.menuContainer}>{Menu}</div>
            <div style={styles.checkAllContainer}>
                <Checkbox style={styles.checkbox} checked={progressComplete} onClick={() => checkAll()} />
                <h3 style={styles.checkAllLabel}>
                    <FormattedMessage {...messages.checkAll} />
                </h3>
            </div>
        </div>
    )

    // A number of these views use a non-standard saving pattern to avoid re-renders
    // 1. use a boolean useState hook set to false: const [updateProduct, setUpdateProduct] = useState(false)
    // 2. set the useState hook to an object when the data is updated: setUpdateProduct({ ...dataToBeUpdated })
    // 3. trigger updating the actual product in useEffect: useEffect(() => { if (updateProduct) { update(updateProduct) } }, [updateProduct])
    // Summary (all 3), avalancheProblems, dangerRatings, Confidence, and Review use this pattern

    const right = (
        <>
            {view === WEATHER_SUMMARY_VIEW && (
                <Summary
                    sectionTitle={WEATHER_SUMMARY_VIEW}
                    update={update}
                    dayDetails={dayDetails}
                    setReviewDrawerVisible={setReviewDrawerVisible}
                    preview={preview}
                    compressed={compressed}
                    colWidth={colWidth}
                />
            )}
            {view === SNOWPACK_SUMMARY_VIEW && (
                <Summary
                    sectionTitle={SNOWPACK_SUMMARY_VIEW}
                    update={update}
                    dayDetails={dayDetails}
                    setReviewDrawerVisible={setReviewDrawerVisible}
                    preview={preview}
                    compressed={compressed}
                    colWidth={colWidth}
                />
            )}
            {view === AVALANCHE_SUMMARY_VIEW && (
                <Summary
                    sectionTitle={AVALANCHE_SUMMARY_VIEW}
                    update={update}
                    dayDetails={dayDetails}
                    setReviewDrawerVisible={setReviewDrawerVisible}
                    preview={preview}
                    compressed={compressed}
                    colWidth={colWidth}
                />
            )}
            {view === 'avalancheProblems' && content.type !== 'highwayforecast' && (
                <AvalancheProblems
                    dayDetails={dayDetails}
                    update={update}
                    setReviewDrawerVisible={setReviewDrawerVisible}
                    preview={preview}
                    compressed={compressed}
                    colWidth={colWidth}
                />
            )}
            {view === 'avalancheProblems' && content.type === 'highwayforecast' && (
                <HwyFxAvalancheProblems
                    dayDetails={dayDetails}
                    update={update}
                    setReviewDrawerVisible={setReviewDrawerVisible}
                    preview={preview}
                    compressed={compressed}
                    colWidth={colWidth}
                />
            )}
            {view === 'dangerRatings' && (
                <DangerRatings
                    dayDetails={dayDetails}
                    update={update}
                    setReviewDrawerVisible={setReviewDrawerVisible}
                    preview={preview}
                    compressed={compressed}
                    colWidth={colWidth}
                />
            )}
            {view === 'highwayHazards' && <HighwayHazard update={update} compressed={compressed} />}
            {view === 'confidence' && (
                <Confidence
                    dayDetails={dayDetails}
                    update={update}
                    setReviewDrawerVisible={setReviewDrawerVisible}
                    preview={preview}
                    compressed={compressed}
                    colWidth={colWidth}
                />
            )}
            {view === 'media' && <Media update={update} compressed={compressed} />}
            {view === 'communications' && <Communications update={update} compressed={compressed} />}
            {view === 'review' && <Review update={update} compressed={compressed} />}
            {reviewDrawerVisible && (
                <Drawer
                    title={<FormattedMessage {...messages.review} />}
                    placement="right"
                    onClose={() => setReviewDrawerVisible(false)}
                    visible={reviewDrawerVisible}
                    contentWrapperStyle={{
                        width: '36vw',
                        marginTop: '2px',
                        paddingTop: '0px',
                        borderLeft: '1px solid var(--grey-light)',
                    }}
                >
                    <ReviewNotesEditor update={update} dayDetails={dayDetails} />
                </Drawer>
            )}
        </>
    )

    return !compressed ? (
        <>
            <TwoColLayoutPrimaryRight
                leftFraction={layoutFraction}
                left={left}
                leftRole="navigation"
                right={right}
                rightRole="main"
                boxShadow={false}
            />
            <Assistant />
        </>
    ) : (
        <>
            <div style={styles.dropMenu}>
                <Select style={{ width: 200 }} value={view} onSelect={handleSectionClick}>
                    {DropDownMenu}
                </Select>
            </div>
            {right}
        </>
    )
}

const styles = {
    title: {
        fontWeight: '400',
        marginBottom: '5px',
    },
    header: {
        padding: 'var(--s1) var(--s0)',
        borderBottom: '1px solid var(--grey-lt)',
    },
    forecaster: {
        marginBottom: 'var(--s1)',
    },
    menuContainer: {
        width: '100%',
    },
    left: {
        background: 'var(--background-colour)',
        height: '100%',
        minWidth: '240px',
    },
    tag: {
        height: '20px',
        marginTop: '3px',
    },
    checkAllButton: {
        margin: 'var(--s0)',
    },
    checkAllContainer: {
        padding: '0.7rem var(--s0) 0.5rem',
        borderTop: '1px solid var(--grey-lighter)',
        borderBottom: '1px solid var(--grey-lighter)',
        background: 'var(--background-colour)',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    checkAllLabel: {
        marginLeft: 'var(--s0)',
        fontWeight: '300',
    },
    checkbox: {
        marginTop: 'var(--s-5)',
    },
    dropMenu: {
        marginBottom: 'var(--s1)',
    },
}
