import useSWRImmutable from 'swr/immutable'

import { useLocale } from 'stores/UserStore'
import { fetchPolygons } from 'services/Polygons'

export const usePolygons = () => {
    const locale = useLocale()

    const key = ['/geospatial/polygons' + locale]

    const { data, isLoading } = useSWRImmutable(key, () => fetchPolygons(locale))

    if (isLoading) {
        return null
    }

    return data.data
}
