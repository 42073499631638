import { createEmptyProduct } from './Product'

export const SPECIAL_PRODUCT_TYPES = [
    {
        label: 'specialProductTypeWarning',
        value: 'warning',
        colour: '#FF0000',
    },
    {
        label: 'specialProductTypeWatch',
        value: 'watch',
        colour: '#FFA500',
    },
    {
        label: 'specialProductTypeSpecialAdvisory',
        value: 'specialAdvisory',
        colour: '#FFFF00',
    },
]

export const getLabelForType = (type) => {
    return SPECIAL_PRODUCT_TYPES.find((specialProduct) => specialProduct.value === type).label
}

export const getColourForType = (type) => {
    return SPECIAL_PRODUCT_TYPES.find((option) => option.value === type)?.colour || '#FF0000'
}

export const createEmptySpecialProduct = (locale = 'en') => {
    return {
        ...createEmptyProduct('specialproduct'),
        message: {
            [locale]: '',
        },
        communications: {
            headline: {
                [locale]: '',
            },
            sms: {
                [locale]: '',
            },
        },
    }
}
