import React, { useEffect, useState } from 'react'

import { Segmented } from 'antd'
import { FormattedMessage } from 'react-intl'

import { uuid } from 'utils/String'
import { noop } from 'utils/function'
import messages from 'services/intl/messageDefinitions'
import { useContent, useDaysInView } from 'stores/ForecastStore'
import { EditScreenLayout } from 'components/EditScreenLayout/EditScreenLayout'
import { DayCardLayout } from 'components/DayCardLayout/DayCardLayout'
import { DayCard } from 'components/DayCard/DayCard'
import { ConfidenceSelectorForm } from 'components/SelectorForm/ConfidenceSelectorForm'
import './Confidence.css'

const MAX_CONFIDENCE_STATEMENTS = 3

export const Confidence = ({ update, dayDetails, setReviewDrawerVisible, preview, compressed, colWidth }) => {
    const content = useContent()
    const [cards, setCards] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [updateStatements, setUpdateStatements] = useState(false)
    const [updateRatings, setUpdateRatings] = useState(false)
    const [cloneNext, setCloneNext] = useState(false)
    const [cloneAll, setCloneAll] = useState(false)
    const daysInView = useDaysInView()

    // create array that contains objects that have all day info including content
    const structureDayData = (data) => {
        return dayDetails.map((detail, d) => {
            const dayIndex = data.findIndex((el) => el.position === detail.position)

            let dataDay = { rating: 'low', statements: [] }
            if (dayIndex !== -1) {
                dataDay = data[dayIndex]
            }

            return {
                // DayCard Setup
                value: d + 1,
                type: d == 0 ? 'Nowcast' : 'Forecast',
                date: detail.date,
                day: detail.day,

                // DayCard Content
                position: d,
                rating: dataDay.rating,
                statements: dataDay.statements ? dataDay.statements : [],
            }
        })
    }

    const cloneToNextDay = (day) => {
        setCloneNext(day.position)
    }

    const cloneToAll = (day) => {
        setCloneAll(day.position)
    }

    const reviewNote = () => {
        setReviewDrawerVisible(true)
    }

    const actionHandlers = {
        cloneNext: (day) => cloneToNextDay(day),
        cloneAll: (day) => cloneToAll(day),
        reviewNote: () => reviewNote(),
        preview: () => preview(),
    }

    const generateDayCards = (structuredDays) => {
        return structuredDays.map((day, i) => {
            const handleRatingChange = (changeValues) => {
                setUpdateRatings({ day: i, rating: changeValues })
            }

            const selectStatementHandler = (newSelectedStatements) => {
                setUpdateStatements({ day: i, statements: newSelectedStatements.map((s) => s.key) })
            }

            return (
                <DayCard
                    day={day}
                    key={uuid()}
                    cardContent={
                        <>
                            <Segmented
                                options={[
                                    { value: 'low', label: <FormattedMessage {...messages.confidenceLow} /> },
                                    { value: 'moderate', label: <FormattedMessage {...messages.confidenceModerate} /> },
                                    { value: 'high', label: <FormattedMessage {...messages.confidenceHigh} /> },
                                ]}
                                defaultValue={day.rating}
                                onChange={handleRatingChange}
                                size="large"
                                style={styles.segmented}
                                block
                                data-test={`confidenceRating${day.position}`}
                            />
                            <ConfidenceSelectorForm
                                selectedKeys={day.statements}
                                selectHandler={selectStatementHandler}
                                maxHeight="63vh"
                                limit={MAX_CONFIDENCE_STATEMENTS}
                            />
                        </>
                    }
                    actionHandlers={actionHandlers}
                    dataProvider={noop}
                />
            )
        })
    }

    useEffect(() => {
        setDataLoaded(false)
    }, [daysInView])

    useEffect(() => {
        // This is only truthy once when content and statements are loaded
        if (!dataLoaded && Object.keys(content).length > 0 && dayDetails.length > 0) {
            setDataLoaded(true)

            const unstructured = content.confidence?.days ? content.confidence.days : []
            const structured = structureDayData(unstructured)
            setCards(generateDayCards(structured))
        }
    }, [content, dayDetails])

    const generateNewDays = (content) => {
        if (content.confidence?.days) {
            return content.confidence?.days
        }

        return content.forecastDays.map((day, i) => {
            return {
                // date: moment(day.date).format('YYYY-MM-DD'),
                position: i,
                rating: 'low',
                statements: [],
            }
        })
    }

    // Update Statements
    useEffect(() => {
        if (updateStatements !== false) {
            setUpdateStatements(false)

            const newDays = generateNewDays(content).map((day, i) => {
                return i === updateStatements.day ? { ...day, statements: updateStatements.statements } : day
            })

            // send API update
            update({ ...content, confidence: { days: newDays } })
        }
    }, [content, updateStatements])

    // Update Ratings
    useEffect(() => {
        if (updateRatings !== false) {
            setUpdateRatings(false)

            const newDays = generateNewDays(content).map((day, i) => {
                return i === updateRatings.day ? { ...day, rating: updateRatings.rating } : day
            })

            // send API update
            update({ ...content, confidence: { days: newDays } })
        }
    }, [content, updateRatings])

    // Clone to Next Day
    useEffect(() => {
        if (cloneNext !== false) {
            setCloneNext(false)

            let newDays = generateNewDays(content)
            const dayToBeCloned = newDays[cloneNext]
            newDays[cloneNext + 1] = { ...dayToBeCloned, position: newDays[cloneNext + 1].position }

            // send API update
            update({ ...content, confidence: { days: newDays } })

            // regenerate cards
            const structured = structureDayData(newDays)
            setCards(generateDayCards(structured))
        }
    }, [content, cloneNext])

    // Clone to All Days
    useEffect(() => {
        if (cloneAll !== false) {
            setCloneAll(false)

            let newDays = generateNewDays(content)
            const dayToBeCloned = newDays[cloneAll]
            newDays = newDays.map((day) => {
                return { ...day, rating: dayToBeCloned.rating, statements: dayToBeCloned.statements }
            })

            // send API update
            update({ ...content, confidence: { days: newDays } })

            // regenerate cards
            const structured = structureDayData(newDays)
            setCards(generateDayCards(structured))
        }
    }, [content, cloneAll])

    return (
        <>
            <EditScreenLayout dayDetails={dayDetails} compressed={compressed}>
                <DayCardLayout dayCards={cards} parentColWidth={colWidth} />
            </EditScreenLayout>
        </>
    )
}

const styles = {
    segmented: {
        marginTop: 'var(--s0)',
        marginBottom: 'var(--s1)',
    },
}
