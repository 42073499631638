import React, { useCallback, useState } from 'react'

import { Button, Divider, Modal, Typography, Row, Col } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import { useLocale } from 'stores/UserStore'
import { putWeakLayer, deleteWeakLayer } from 'services/WeakLayers'
import { useUpdateLayer, useEditingWeakLayer, useSetEditingWeakLayer, usePolygons } from 'stores/WeakLayerStore'
import { Stat } from 'components/Stat/Stat'
import { Date } from 'components/Date/Date'
import { PolygonList } from 'components/PolygonList/PolygonList'
import { Comments } from 'components/Comments/Comments'
import { AspectElevation } from 'components/AspectElevation/AspectElevation'
import { LayerForm } from './LayerForm'
import { useSendNotification } from 'utils/Notifications'
import messages from 'services/intl/messageDefinitions'

export const Details = ({ item, closeAndReload }) => {
    const intl = useIntl()
    const [deleteModalVisible, setModalVisibilty] = useState(false)
    const { Title } = Typography
    const editing = useEditingWeakLayer()
    const setEditing = useSetEditingWeakLayer()
    const locale = useLocale()
    const updateWeakLayer = useUpdateLayer()
    const sendNotification = useSendNotification()

    const updateLayer = useCallback(
        async (data, locale) => {
            const response = await putWeakLayer(data, locale)
            updateWeakLayer(response.data)
            sendNotification(intl.formatMessage({ ...messages.weakLayerSaved }, { name: data.name }))
        },
        [updateWeakLayer]
    )

    const deleteLayer = useCallback(
        async (id, locale) => {
            await deleteWeakLayer(id, locale)
            sendNotification(intl.formatMessage({ ...messages.weakLayerDeleted }, { name: item.name }))
            setModalVisibilty(false)
            closeAndReload()
        },
        [closeAndReload]
    )

    // Updates the various fields in the weak layer
    // details should be in the format: [{key: <field to be changed> value: <updated values>}, {...}]
    const update = useCallback(
        (details) => {
            const data = { ...item }
            for (let d in details) {
                const detail = details[d]
                data[detail.key] = detail.value
            }
            updateLayer(data, locale)
        },
        [item, locale, updateLayer]
    )

    const toggleEdit = () => {
        setEditing(!editing)
    }

    const confirmDeleteModal = (
        <Modal
            title={<FormattedMessage {...messages.warning} />}
            visible={deleteModalVisible}
            onOk={() => deleteLayer(item.id, locale)}
            onCancel={() => setModalVisibilty(false)}
            okText={<FormattedMessage {...messages.yesConfirm} />}
            cancelText={<FormattedMessage {...messages.cancel} />}
            data-test={'deleteModal'}
        >
            <p>
                <FormattedMessage {...messages.deleteWeakLayerConfirmation} values={{ name: item.name }} />
            </p>
        </Modal>
    )

    const detailsView = (
        <>
            <Title level={2} style={styles.title}>
                {item.name}
            </Title>
            <Date label={'lastModified'} time={true} value={item.lastModifiedDate} />
            <div style={styles.container}>
                <Divider orientation="left" plain>
                    <FormattedMessage {...messages.details} />
                </Divider>
                <Row>
                    <Col style={styles.detailCol} span={12}>
                        <Stat label={'status'} value={item.status} />
                    </Col>
                    <Col style={styles.detailCol} span={12}>
                        <Stat label={'buried'} value={item.burialDate} type={'date'} />
                    </Col>
                </Row>
                <Row>
                    <Col style={styles.detailCol} span={12}>
                        <Stat label={'depth'} value={item.depth} unit={'cm'} type={'tuple'} />
                    </Col>
                    <Col style={styles.detailCol} span={12}>
                        <Stat label={'thickness'} value={item.thickness} unit={'mm'} type={'tuple'} />
                    </Col>
                </Row>
                <Row>
                    <Col style={styles.detailCol} span={24}>
                        <Stat label={'grainType'} value={item.grainType} type={'text'} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={styles.label}>
                            <FormattedMessage {...messages.aspectElevation} />
                        </p>
                    </Col>
                </Row>
                <Row style={styles.grid} align="center">
                    <Col>
                        <AspectElevation aspectElevations={item.aspectElevations} colour={item.colour} />
                    </Col>
                </Row>
                <PolygonList polygonList={item.polygons} colour={item.colour} usePolygons={usePolygons} />
            </div>
            <div style={styles.buttonContainer}>
                <Button type="primary" onClick={toggleEdit} style={styles.editButton} data-test={'weakLayerEditButton'}>
                    <FormattedMessage {...messages.edit} />
                </Button>
            </div>
            <Comments comments={item.comments} />
        </>
    )

    const editView = (
        <>
            <LayerForm toggleEdit={toggleEdit} update={update} autoUpdate={true} initialValues={item} />
        </>
    )

    let contentView = editing ? editView : detailsView

    const deleteButton = (
        <div style={styles.buttonContainer}>
            <Button
                onClick={() => setModalVisibilty(true)}
                type="primary"
                danger
                style={styles.deleteButton}
                data-test={'deleteWeakLayerButton'}
            >
                <FormattedMessage {...messages.deleteWeakLayer} />
            </Button>
        </div>
    )

    return (
        <>
            {contentView}
            {editing && deleteButton}
            {confirmDeleteModal}
        </>
    )
}

const styles = {
    detailCol: {
        marginBottom: '15px',
    },
    closeIcon: {
        cursor: 'pointer',
        position: 'absolute',
        right: '10px',
        top: '15px',
        color: '#b2b1b1',
        fontSize: '17px',
    },
    title: {
        fontWeight: '400',
    },
    container: {
        marginTop: 'var(--s2)',
    },
    buttonContainer: {
        display: 'flex',
    },
    grid: {
        marginBottom: 'var(--s0)',
    },
    editButton: {
        marginTop: 'var(--s1)',
        marginLeft: 'auto',
    },
    deleteButton: {
        marginTop: 'var(--s1)',
        marginLeft: 'auto',
    },
    label: {
        fontSize: '14px',
        color: 'var(--grey)',
        marginBottom: '0px',
        textTransform: 'capitalize',
        fontWeight: '600',
    },
}
