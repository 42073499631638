import { createEmptyProduct } from './Product'
import { DEFAULT_VALUE_CONFIDENCE, DEFAULT_VALUE_REVIEW } from './Constants'

export const createEmptyHighwayForecast = (locale = 'en') => {
    return {
        ...createEmptyProduct('highwayforecast', locale),
        weatherSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        snowpackSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        avalancheSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        avalancheProblems: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i }
            }),
        },
        hazards: [],
        confidence: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, rating: DEFAULT_VALUE_CONFIDENCE, statements: [] }
            }),
        },
        communications: {
            headline: {
                [locale]: '',
            },
            sms: {
                [locale]: '',
            },
        },
        review: {
            status: DEFAULT_VALUE_REVIEW,
            notes: '',
        },
        isTranslated: false,
    }
}

export const createEmptyHighwayDiscussion = (locale = 'en') => {
    return {
        ...createEmptyProduct('highwaydiscussion'),
        message: {
            [locale]: '',
        },
        communications: {
            headline: {
                [locale]: '',
            },
            sms: {
                [locale]: '',
            },
        },
    }
}

export const createEmptyMitigation = () => {
    return createEmptyProduct('mitigation')
}
