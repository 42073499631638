import React, { useState, useEffect, useCallback } from 'react'

import { Form, Input, Row, Col, Button } from 'antd'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'

import { COLOUR_MAP, COLOUR_NAMES } from 'utils/Product'
import { createEmptyMitigation } from 'utils/Highways'
import { useSendNotification } from 'utils/Notifications'
import { postMitigation, fetchMitigations } from 'services/Mitigation'
import { useLocale, useId } from 'stores/UserStore'
import { fetchUsers } from 'services/Users'
import messages from 'services/intl/messageDefinitions'
import { MitigationStore } from 'stores/MitigationStore'
import { LimitedColourPicker } from 'components/ColorPicker/LimitedColourPicker'
import { Instructions } from 'components/Map/Instructions'
import { RangePicker } from 'components/RangePicker/RangePicker'
import { Select } from 'components/Dropdown/Select'

export const CreateForm = ({
    toggleEdit,
    update,
    initialValues = createEmptyMitigation(),
    editing,
    closeDrawer,
    autoUpdate = false,
}) => {
    const intl = useIntl()
    const [disabledForm, toggleDisabledForm] = useState(editing && !initialValues.userID)
    const [name, setName] = useState(initialValues.name)
    const [nameTouched, setNameTouched] = useState(false)
    const [forecasters, updateForecasters] = useState([])
    const [dateRange, setDateRange] = useState([initialValues.issueDateTime, initialValues.expiryDateTime])
    const [form] = Form.useForm()
    const userId = useId()
    const draftMitigation = MitigationStore.useDraftProduct()
    const updateDraftMitigation = MitigationStore.useUpdateDraftProduct()
    const resetDraft = MitigationStore.useResetDraftProduct()
    const locale = useLocale()
    const updateMitigations = MitigationStore.useUpdateProducts()
    const sendNotification = useSendNotification()

    // Set the user ID to the current user if it is not set and we're not editing an existing forecast (ie a cloned forecast)
    const formattedUserId = initialValues.userID ? initialValues.userID : editing ? null : userId
    const formattedInitialValues = {
        ...initialValues,
        name: initialValues.name,
        userID: formattedUserId,
        times: [moment(initialValues.issueDateTime), moment(initialValues.expiryDateTime)],
    }
    const [colour, setColour] = useState(editing ? formattedInitialValues.colour : draftMitigation.colour)

    const createNewMitigation = (formData, errors) => {
        const newSetup = createEmptyMitigation(locale)
        newSetup.colour = draftMitigation.colour // TODO have only a single source of truth
        newSetup.status = 'draft'
        newSetup.name = formData.name
        newSetup.userID = formData.userID
        newSetup.expiryDateTime = dateRange[1]
        newSetup.issueDateTime = dateRange[0]
        newSetup.polygons = draftMitigation.polygons // TODO have only a single source of truth

        apiPost(newSetup, locale)
    }

    const apiPost = useCallback(async (data, locale) => {
        await postMitigation(data, locale)
        sendNotification(intl.formatMessage({ ...messages.mitigationSaved }, { name: data.name }))
        getMitigations()
        closeDrawer()
        form.resetFields()
    }, [])

    const getMitigations = useCallback(async () => {
        const response = await fetchMitigations(locale)
        resetDraft()
        updateMitigations(response.data)
    }, [])

    const cancelDraft = () => {
        closeDrawer()
        form.resetFields()
    }

    const getForecasters = useCallback(async () => {
        const response = await fetchUsers()
        generateForecasterOptions(response.data)
    }, [])

    const generateForecasterOptions = (userData) => {
        const forecasterOptions = userData.map((item, i) => {
            return (
                <Select.Option key={i} value={item.id}>
                    {item.name}
                </Select.Option>
            )
        })
        updateForecasters(forecasterOptions)
    }

    const updateDetails = useCallback(
        (changedField) => {
            const updateData = {
                key: null,
                value: null,
            }

            switch (changedField.key) {
                case 'name':
                    // wait a couple seconds to let user finish typing
                    const formattedName = changedField.value.trim() // remove trailing or procedding white space
                    if (formattedName.length >= 3 && formattedName.length <= 100) {
                        setName(formattedName)
                        setNameTouched(true)
                    }
                    break
                case 'times':
                    break
                default:
                    updateData.value = changedField.value
                    updateData.key = changedField.key
            }

            if (updateData.value && updateData.key) {
                update([updateData])
            }
        },
        [update]
    )

    const updateDates = (dates) => {
        setDateRange(dates)
        update([
            {
                key: 'expiryDateTime',
                value: dates[1],
            },
            {
                key: 'issueDateTime',
                value: dates[0],
            },
        ])
    }

    const changeColour = useCallback(
        (colour) => {
            setColour(colour)
            if (editing && autoUpdate) {
                const updateData = {
                    key: 'colour',
                    value: colour,
                }
                updateDetails(updateData)
            } else {
                updateDraftMitigation({
                    ...draftMitigation,
                    colour,
                })
            }
        },
        [autoUpdate, draftMitigation, editing, updateDetails, updateDraftMitigation]
    )

    const autoChecker = (changeValues) => {
        const changedField = Object.keys(changeValues)[0]
        const parsedValue = Object.values(changeValues)[0]
        const updateData = { key: changedField, value: parsedValue }

        if (changedField === 'userID') {
            // now that there is a forecaster allow for editing other fields
            toggleDisabledForm(false)
        }

        if (autoUpdate) {
            updateDetails(updateData)
        }
    }

    const doneAction = editing ? (
        <div style={styles.buttonContainer}>
            <Button type="primary" onClick={toggleEdit} data-test={'closeEditingButton'}>
                <FormattedMessage {...messages.closeEditing} />
            </Button>
        </div>
    ) : (
        <div style={styles.buttonContainer}>
            <Button onClick={cancelDraft} style={styles.cancelButton}>
                <FormattedMessage {...messages.cancel} />
            </Button>
            <Button type="primary" onClick={() => form.submit()} data-test={'confirmCreate'}>
                <FormattedMessage {...messages.create} />
            </Button>
        </div>
    )

    const title = !editing ? (
        <h1 style={styles.title}>
            <FormattedMessage {...messages.createMitigation} />
        </h1>
    ) : (
        <h1 style={styles.title}>
            <FormattedMessage {...messages.editMitigation} />
        </h1>
    )

    useEffect(() => {
        getForecasters()
    }, [])

    // prevent multiple PUT request when user is typing
    useEffect(() => {
        const handler = setTimeout(() => {
            if (autoUpdate && nameTouched) {
                const updateData = {
                    key: 'name',
                    value: name,
                }
                update([updateData])
                setNameTouched(false)
            }
        }, 1500)

        return () => {
            clearTimeout(handler)
        }
    }, [autoUpdate, name, nameTouched, update])

    return (
        <div>
            {title}
            <Instructions />
            <Form
                form={form}
                layout="horizontal"
                onFinish={createNewMitigation}
                autoComplete="on"
                onValuesChange={autoChecker}
                initialValues={formattedInitialValues}
            >
                <Form.Item
                    label={<FormattedMessage {...messages.mitigationName} />}
                    labelCol={{ span: 24 }}
                    name="name"
                    rules={[
                        { required: true, message: <FormattedMessage {...messages.nameRequired} /> },
                        { min: 3, message: <FormattedMessage {...messages.nameTooShort} /> },
                        { max: 100, message: <FormattedMessage {...messages.nameTooLong} /> },
                    ]}
                >
                    <Input disabled={disabledForm} />
                </Form.Item>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={<FormattedMessage {...messages.forecaster} />}
                            labelCol={{ span: 24 }}
                            name="userID"
                            rules={[{ required: true }]}
                        >
                            <Select data-test={'forecaster'}>{forecasters}</Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={<FormattedMessage {...messages.colour} />}
                            labelCol={{ span: 24 }}
                            name="colour"
                            rules={[{ required: false }]}
                        >
                            <LimitedColourPicker
                                colourMap={COLOUR_MAP}
                                colourNames={COLOUR_NAMES}
                                disabled={disabledForm}
                                initialColour={colour}
                                onUpdate={changeColour}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label={<FormattedMessage {...messages.issueAndExpiry} />}
                    labelCol={{ span: 24 }}
                    name="times"
                    rules={[{ required: true, message: <FormattedMessage {...messages.forecastValidationDate} /> }]}
                >
                    <RangePicker
                        initValues={formattedInitialValues.times}
                        onChange={updateDates}
                        disabled={disabledForm}
                    />
                </Form.Item>
            </Form>
            {doneAction}
        </div>
    )
}

const styles = {
    button: {
        margin: '0 var(--s0) 0 0',
        color: '#fff',
    },
    closeEditing: {
        float: 'right',
        marginBottom: 'var(--s1)',
    },
    container: {
        display: 'flex',
    },
    cancelButton: {
        margin: '0 var(--s0) 0 0',
        color: '#fff',
        background: 'var(--grey)',
        borderColor: 'var(--grey)',
    },
    deleteButton: {
        marginTop: 'var(--s1)',
        marginLeft: 'auto',
        position: 'fixed',
        bottom: 'var(--s0)',
        right: 'var(--s0)',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'right',
    },
    errorMessage: {
        fontSize: '14px',
        marginTop: '-25px',
        color: 'var(--red)',
        fontWeight: '300',
    },
    hide: {
        display: 'none',
    },
    show: {
        display: 'block',
    },
    aspectRose: {
        marginBottom: 'var(--s0)',
    },
    sudoLabel: {
        fontSize: '14px',
    },
    title: {
        fontWeight: 300,
    },
}
