import React, { useEffect, useRef, useState, useContext, createContext, useMemo } from 'react'
import mapbox from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { LOCALES } from 'utils/Locales'
import 'mapbox-gl/dist/mapbox-gl.css'

export const Map = ({ options, locale, children }) => {
    const containerRef = useRef(null)
    const [map, setMap] = useState(null)
    const [ready, setReady] = useState(false)
    const { currentTheme } = useThemeSwitcher()
    const mapStyleUrl =
        currentTheme === 'light' ? process.env.REACT_APP_MAPBOX_STYLE : process.env.REACT_APP_MAPBOX_STYLE_DARK

    useEffect(() => {
        const map = new mapbox.Map({
            style: mapStyleUrl,
            dragRotate: false,
            accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
            ...options,
            container: containerRef.current,
        })

        window.map = map

        map.once('load', () => {
            setReady(true)
        })

        const languageControl = new MapboxLanguage({
            supportedLanguages: Array.from(LOCALES),
            defaultLanguage: locale,
        })

        map.addControl(languageControl)

        setMap(map)

        return () => {
            map.remove()
        }
    }, [])

    return (
        <context.Provider value={map}>
            <div ref={containerRef} style={MAP_CONTAINER_STYLE} data-test="map" />
            {React.Children.toArray(children).filter((child) => {
                switch (child.type) {
                    // case Layer:
                    case WithMapReady:
                        return ready
                    default:
                        return true
                }
            })}
        </context.Provider>
    )
}

export function useMap() {
    return useContext(context)
}

export function WithMapReady({ children }) {
    return children
}

// Utils and constants
const context = createContext(null)
const MAP_CONTAINER_STYLE = {
    height: '100%',
}

const ON = {}
