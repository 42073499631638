import React, { useEffect, useState } from 'react'

import { Row, Col, Modal, InputNumber, Form } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl'

import { SENSITIVITY_TYPES } from 'utils/Snowpack/Sensitivity'
import { PROBLEM_TYPES } from 'utils/Snowpack/ProblemTypes'
import { DISTRIBUTION_TYPES } from 'utils/Snowpack/Distribution'
import { SIZES } from 'utils/Snowpack/Avalanche'
import { uuid } from 'utils/String'
import { AvalancheProblemTypeInfoTable } from 'components/Documentation/AvalancheProblemTypeInfoTable'
import { AvalancheDistributionInfoTable } from 'components/Documentation/AvalancheDistributionTable'
import { WeakLayerSelector } from 'components/WeakLayerSelector/WeakLayerSelector'
import { SensitivityTable } from 'components/Documentation/SensitivityTable'
import messages from 'services/intl/messageDefinitions'
import { Select } from 'components/Dropdown/Select'

import './AvalancheProblem.css'

export const AvalancheProblemForm = ({
    problem,
    initialValues,
    content,
    updateProblem,
    setSize,
    form,
    depth,
    setDepth,
    weakLayerSelectError,
}) => {
    const [modalVisible, setModalVisibility] = useState(false)
    const [showDepthError, setShowDepthError] = useState(false)
    const [modalContent, setModalContent] = useState()
    const { Option } = Select
    const intl = useIntl()

    const autoChecker = (values) => {
        const key = Object.keys(values)[0]
        if (values.typicalSize) {
            values.typicalSize = Number(values.typicalSize) // Force this to be a number as native html <selects> return strings
        }

        switch (key) {
            case 'typicalDepth':
                // this is handled by a useEffect method, so we don't want to update here
                break
            case 'weakLayer':
                break
            case 'typicalSize':
                setSize(values.typicalSize)
            // break
            default:
                updateProblem({
                    ...initialValues,
                    ...values,
                })
        }
    }

    const selectWeakLayer = (weakLayer) => {
        updateProblem({
            ...initialValues,
            weakLayer,
        })
    }

    const generateSelectOptions = (list) => {
        return list.map((item) => {
            const label = typeof item === 'number' ? item : <FormattedMessage {...messages[item]} />
            return (
                <Option key={uuid()} value={item}>
                    {label}
                </Option>
            )
        })
    }

    const showAvalancheProblemTypeInfo = () => {
        setModalContent(<AvalancheProblemTypeInfoTable />)
        setModalVisibility(true)
    }

    const showAvalancheDistributionInfo = () => {
        setModalContent(<AvalancheDistributionInfoTable />)
        setModalVisibility(true)
    }

    const showSensitivityTable = () => {
        setModalContent(<SensitivityTable />)
        setModalVisibility(true)
    }

    const updateDepth = (val, key) => {
        setDepth({
            ...depth,
            [key]: val,
        })
    }

    useEffect(() => {
        if (depth.max && depth.min && depth.min > depth.max) {
            setShowDepthError(true)
        } else {
            setShowDepthError(false)
        }
    }, [depth])

    return (
        <>
            <Form form={form} layout="horizontal" autoComplete="off" onValuesChange={autoChecker}>
                <div>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={11}>
                            <Form.Item
                                style={styles.itemContainer}
                                label={
                                    <>
                                        <FormattedMessage {...messages.avalancheProblemType} />
                                        <QuestionCircleOutlined
                                            onClick={showAvalancheProblemTypeInfo}
                                            style={styles.infoIcon}
                                        />
                                    </>
                                }
                                labelCol={{ span: 24 }}
                                name="problemType"
                                rules={[{ required: true, message: <FormattedMessage {...messages.required} /> }]}
                                data-test={'problemTypeInput'}
                            >
                                <Select>{generateSelectOptions(PROBLEM_TYPES)}</Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={13}>
                            <Form.Item
                                style={styles.itemContainer}
                                label={<FormattedMessage {...messages.weakLayers} />}
                                labelCol={{ span: 24 }}
                                name="weakLayer"
                                rules={[{ required: true, message: <FormattedMessage {...messages.required} /> }]}
                            >
                                <WeakLayerSelector
                                    currentZone={content.polygons}
                                    onSelect={selectWeakLayer}
                                    selected={problem.weakLayer}
                                    errorStatus={weakLayerSelectError}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={11}>
                            <Form.Item
                                style={styles.itemContainer}
                                label={
                                    <>
                                        <FormattedMessage {...messages.distribution} />
                                        <QuestionCircleOutlined
                                            onClick={showAvalancheDistributionInfo}
                                            style={styles.infoIcon}
                                        />
                                    </>
                                }
                                labelCol={{ span: 24 }}
                                name="distribution"
                                rules={[{ required: true, message: <FormattedMessage {...messages.required} /> }]}
                                data-test={'distribution'}
                            >
                                <Select>{generateSelectOptions(DISTRIBUTION_TYPES)}</Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={13}>
                            <Form.Item
                                style={styles.itemContainer}
                                label={
                                    <>
                                        <FormattedMessage {...messages.sensitivity} />
                                        <QuestionCircleOutlined
                                            onClick={showSensitivityTable}
                                            style={styles.infoIcon}
                                        />
                                    </>
                                }
                                labelCol={{ span: 24 }}
                                name="sensitivity"
                                rules={[{ required: true, message: <FormattedMessage {...messages.required} /> }]}
                                data-test={'sensitivity'}
                            >
                                <Select>{generateSelectOptions(SENSITIVITY_TYPES)}</Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <div style={styles.inputContainer}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={10}>
                            <Form.Item
                                style={styles.itemContainer}
                                label={<FormattedMessage {...messages.typicalSize} />}
                                labelCol={{ span: 24 }}
                                name="typicalSize"
                                rules={[{ required: true, message: <FormattedMessage {...messages.required} /> }]}
                                data-test={'typicalSize'}
                            >
                                <Select>{generateSelectOptions(SIZES)}</Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={14}>
                            <Form.Item
                                style={styles.itemContainer}
                                label={<FormattedMessage {...messages.depth} />}
                                labelCol={{ span: 24 }}
                                name="typicalDepth"
                                rules={[{ required: false, message: <FormattedMessage {...messages.required} /> }]}
                            >
                                <div data-test={'depthMin'} style={styles.depthContainer}>
                                    <InputNumber
                                        addonAfter="cm"
                                        type="number"
                                        name="min"
                                        min={0}
                                        precision={0}
                                        style={styles.inputNumber}
                                        value={depth.min}
                                        onChange={(val) => updateDepth(val, 'min')}
                                        aria-label={intl.formatMessage({ ...messages.depthFrom })}
                                    />
                                    <span style={styles.toLabel}> to </span>
                                    <InputNumber
                                        addonAfter="cm"
                                        type="number"
                                        min={0}
                                        precision={0}
                                        style={styles.inputNumber}
                                        value={depth.max}
                                        onChange={(val) => updateDepth(val, 'max')}
                                        data-test={'depthMax'}
                                        aria-label={intl.formatMessage({ ...messages.depthTo })}
                                    />
                                </div>
                            </Form.Item>
                            <div style={showDepthError ? styles.show : styles.hide}>
                                <p style={styles.errorMessage}>
                                    <FormattedMessage {...messages.toFromError} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Form>
            <Modal
                centered
                visible={modalVisible}
                canelText={'close'}
                footer={null}
                onCancel={() => setModalVisibility(false)}
                width={'90%'}
            >
                {modalContent}
            </Modal>
        </>
    )
}

const styles = {
    select: {
        width: '100%',
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    infoIcon: {
        margin: '1px 0px 0px 5px',
        cursor: 'pointer',
        color: 'var(--grey)',
    },
    errorMessage: {
        fontSize: '14px',
        marginTop: '-25px',
        color: 'var(--red)',
        fontWeight: '300',
    },
    itemContainer: {
        padding: '0px 5px',
    },
    depthContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    hide: {
        display: 'none',
    },
    show: {
        display: 'block',
    },
    tag: {
        // float: 'right',
        marginBottom: '4px',
        textTransform: 'capitalize',
    },
    tagContainer: {
        paddingTop: '10px',
    },
    toLabel: {
        margin: '4px 7px 0px 7px',
    },
    dropdownLabel: {
        fontWeight: '500',
        marginLeft: '5px',
        fontSize: '12px',
        marginTop: 'var(--s0)',
    },
    dropdownLabelFirst: {
        fontWeight: '500',
        marginLeft: '5px',
        fontSize: '12px',
    },
    layerDisplay: {
        background: 'var(--grey-lighter)',
        width: '100%',
        height: '32px',
        borderRadius: 'var(--radius)',
        padding: '5px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    close: {
        fontSize: '14px',
        marginTop: '4px',
        marginRight: '3px',
        color: 'var(--grey)',
    },
}
