import React from 'react'

import { MitigationStore } from 'stores/MitigationStore'
import { MitigationIcon } from './MitigationIcon'

export const MitigationIconSet = () => {
    const filteredMitigations = MitigationStore.useFilteredProducts()
    const draftMitigation = MitigationStore.useDraftProduct()
    const polygonsGeoJson = MitigationStore.usePolygons()
    const icons = [...filteredMitigations, draftMitigation]
        .map((mitigation) => {
            const { polygons } = mitigation
            return polygons.map((polygonId) => {
                const polygon = polygonsGeoJson.features.find((polygon) => polygon.properties.id === polygonId)

                return {
                    id: polygonId,
                    lngLat: polygon.properties.centroid || [
                        polygon.properties.CENTROID_X,
                        polygon.properties.CENTROID_Y,
                    ],
                }
            })
        })
        .flat()

    return icons.map((icon) => {
        return icon.lngLat && <MitigationIcon key={icon.id} lngLat={icon.lngLat} />
    })
}
