import React, { useEffect } from 'react'

import { FormattedMessage, useIntl } from 'react-intl'

import { AvalancheProblemTypeInfoTable } from 'components/Documentation/AvalancheProblemTypeInfoTable'
import { AvalancheDistributionInfoTable } from 'components/Documentation/AvalancheDistributionTable'
import { DangerRatingTable } from 'components/Documentation/DangerRatingTable'
import { SensitivityTable } from 'components/Documentation/SensitivityTable'
import messages from 'services/intl/messageDefinitions'

export const Documentation = ({}) => {
    const intl = useIntl()

    useEffect(() => {
        document.title = intl.formatMessage(messages.documentation)
    }, [])

    return (
        <div style={styles.container}>
            <div style={styles.sectionOne}>
                <h2 style={styles.label}>
                    <FormattedMessage
                        id="avalancheProblemTypes"
                        defaultMessage={'Avalanche Problem Types'}
                        description="Documentation page/modal"
                    />
                </h2>
                <AvalancheProblemTypeInfoTable />
            </div>
            <div style={styles.section}>
                <h2 style={styles.label}>
                    <FormattedMessage
                        id="distributionTypes"
                        defaultMessage={'Distribution Types'}
                        description="Documentation page/modal"
                    />
                </h2>
                <AvalancheDistributionInfoTable />
            </div>
            <div style={styles.section}>
                <h2 style={styles.label}>
                    <FormattedMessage {...messages.dangerRatings} />
                </h2>
                <DangerRatingTable />
            </div>
            <div style={styles.section}>
                <h2 style={styles.label}>
                    <FormattedMessage {...messages.sensitivity} />
                </h2>
                <SensitivityTable />
            </div>
        </div>
    )
}

const styles = {
    container: {
        padding: 'var(--s4) 0px var(--s4) 0',
        background: 'var(--grey-lighter)',
    },
    label: {
        color: 'var(--orange)',
        marginLeft: '10px',
        fontWeight: '300',
    },
    section: {
        width: '100%',
        background: 'var(--background-colour)',
        padding: 'var(--s2) var(--s3)',
        marginTop: '50px',
    },
    sectionOne: {
        width: '100%',
        background: 'var(--background-colour)',
        padding: 'var(--s2) var(--s3)',
        marginTop: '0px',
    },
}
