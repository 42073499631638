import { DEFAULT_VALUE_DANGER_RATINGS, DEFAULT_VALUE_CONFIDENCE, DEFAULT_VALUE_REVIEW } from './Constants'
import { createEmptyProduct } from './Product'

// TODO: dynamic language
export const createEmptyAvalancheForecast = (locale = 'en') => {
    return {
        ...createEmptyProduct('avalancheforecast', locale),
        weatherSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        snowpackSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        avalancheSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        avalancheProblems: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i }
            }),
        },
        // TODO: coordinate changing this over to days array with backend
        dangerRatings: new Array(4).fill().map((el, i) => {
            return {
                position: i,
                alp: DEFAULT_VALUE_DANGER_RATINGS,
                tln: DEFAULT_VALUE_DANGER_RATINGS,
                btl: DEFAULT_VALUE_DANGER_RATINGS,
            }
        }),
        confidence: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, rating: DEFAULT_VALUE_CONFIDENCE, statements: [] }
            }),
        },
        communications: {
            headline: {
                [locale]: '',
            },
            sms: {
                [locale]: '',
            },
        },
        review: {
            status: DEFAULT_VALUE_REVIEW,
            notes: '',
        },
        isTranslated: false,
    }
}

export const DAY_ONE = new Set(['issueDate', 'issueDatePlusOne'])

// this will only work with [{date: ..., content: ...}]
export const unstructureDayData = (daysList) => {
    return daysList.map((day, d) => {
        return {
            date: day.date,
            content: day.content,
        }
    })
}

// create array that contains objects that have all day info including content
// this will only work with [{date: ..., content: ...}]
export const structureDayData = (data, dayDetails) => {
    const structuredDays = dayDetails.map((detail, d) => {
        const dayIndex = data.findIndex((el) => el.date === detail.date)
        const dataDay = data[dayIndex]
        const current = dataDay ? dataDay : { content: { en: '' }, date: detail.date }
        return {
            date: current.date,
            content: current.content,
            value: d + 1,
            day: detail.day,
            type: d === 0 ? 'Nowcast' : 'Forecast',
        }
    })

    return structuredDays
}
