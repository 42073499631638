import React, { useCallback } from 'react'

import { FormattedMessage } from 'react-intl'
import { Button, Space } from 'antd'

import messages from 'services/intl/messageDefinitions'
import { useLocale } from 'stores/UserStore'
import { addImage } from './helpers'
import { ImageForm } from './ImageForm'

// Input:
// images: [
//     { ...newImage },
// ]
export const ImageFormSet = ({ product, apiPut }) => {
    const locale = useLocale()
    const { media } = product || {}
    const { images } = media || {}

    const updateProduct = useCallback(async (data) => {
        await apiPut(data)
    }, [])

    const handleAddImageClick = () => {
        const updatedProduct = addImage(product, locale)
        updateProduct(updatedProduct)
    }

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            {images && images.length > 0 && (
                <>
                    {[...images].map((image, index) => {
                        return (
                            <ImageForm
                                key={image.id}
                                apiPut={apiPut}
                                product={product}
                                image={image}
                                index={index}
                                count={images.length}
                            />
                        )
                    })}
                </>
            )}
            <Button onClick={handleAddImageClick} data-test={'addImageButton'}>
                <FormattedMessage {...messages.addImage} />
            </Button>
        </Space>
    )
}
