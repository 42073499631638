import { useState } from 'react'

import { Button, Card } from 'antd'
import axios from 'axios'

import { BrainIcon } from './BrainIcon'

import { CloseOutlined } from '@ant-design/icons'
import { useContent } from 'stores/ForecastStore'

const client = axios.create({
    baseURL: 'https://api.openai.com/v1',
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
    },
})

export const Assistant = () => {
    const content = useContent()

    const [chatOpen, setChatOpen] = useState(false)
    const [messages, setMessages] = useState([])

    let contentForChatGPT = content
    if (contentForChatGPT) {
        // remove the polygons from the content
        contentForChatGPT = { ...contentForChatGPT, polygons: [] }
        // remove the polygons from the avalanche problems weak layers
        contentForChatGPT?.avalancheProblems?.days.forEach((day) => {
            day?.problems?.forEach((problem) => {
                if (problem?.weakLayer) {
                    problem.weakLayer.polygons = []
                }
            })
        })
    }

    // add a message to the chat
    const addMessage = async (message) => {
        // If it's the first message, add the forecast content for context
        let newMessages = messages
        if (messages.length === 0) {
            newMessages = [
                ...messages,
                {
                    role: 'system',
                    content:
                        'I am working on an avalanche forecast for the next 3 days. The JSON schema of the forecast is as follows: \n\n' +
                        JSON.stringify(contentForChatGPT, null, 2),
                },
            ]
        }
        newMessages = [...newMessages, { role: 'user', content: message }]

        setMessages([...newMessages, { role: 'assistant', content: 'Thinking...' }])

        const response = await client
            .post('/chat/completions', {
                model: 'gpt-3.5-turbo-16k',
                messages: newMessages,
                n: 1,
            })
            .catch(() => {
                setMessages([
                    ...newMessages,
                    {
                        role: 'assistant',
                        content: 'Sorry, there was an error. Please try again later.',
                    },
                ])
            })

        const responseMessage = response?.data?.choices[0]?.message

        if (responseMessage) {
            setMessages([...newMessages, responseMessage])
        } else {
            setMessages([
                ...newMessages,
                {
                    role: 'assistant',
                    content: 'Sorry, I did not understand that. Please try again.',
                },
            ])
        }
    }

    return (
        <>
            {chatOpen && (
                <Card style={styles.chatWindow} title="GPT Assistant" bodyStyle={styles.chatSpace}>
                    <div style={styles.messageSpace}>
                        {messages.map((message, index) => (
                            <Message key={index} message={message.content} speaker={message.role} />
                        ))}
                    </div>
                    <input
                        style={styles.input}
                        placeholder="Type a message..."
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                addMessage(e.target.value)
                                e.target.value = ''
                            }
                        }}
                    />
                </Card>
            )}
            <Button style={styles.button} onClick={() => setChatOpen(!chatOpen)}>
                {chatOpen ? <CloseOutlined /> : <BrainIcon />}
            </Button>
        </>
    )
}

const Message = ({ message, speaker }) => {
    if (speaker === 'system') return
    // show the message on the left or right depending on who said it
    const messageStyle = speaker === 'user' ? styles.userMessage : styles.botMessage
    return <div style={messageStyle}>{message}</div>
}

const styles = {
    // put button at bottpm right of screen
    button: {
        position: 'fixed',
        bottom: '1rem',
        right: '1rem',
        zIndex: 1000,
        background: 'var(--background-colour)',
        border: '1px solid var(--grey-light)',
        borderRadius: '50%',
        width: '3rem',
        height: '3rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    // put chat at bottom right of screen just above button
    chatWindow: {
        position: 'fixed',
        bottom: '5rem',
        right: '1rem',
        zIndex: 1000,
        width: '30rem',
        height: '40rem',
        display: 'flex',
        flexDirection: 'column',
    },
    // space for messages and input. push them to the bottom
    chatSpace: {
        height: '91%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        flexGrow: 0,
    },
    // space for messages
    messageSpace: {
        flexGrow: 1,
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
    },
    // style for the input box
    input: {
        width: '100%',
        padding: '1rem',
        color: 'var(--text-colour)',
        background: 'var(--grey-lighter)',
    },
    // style for user messages (on the right)
    userMessage: {
        background: 'var(--grey-lighter)',
        padding: '1rem',
        borderRadius: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
        marginRight: '1rem',
        maxWidth: '80%',
        alignSelf: 'flex-end',
    },
    // style for bot messages (on the left)
    botMessage: {
        background: 'var(--grey-light)',
        padding: '1rem',
        borderRadius: '1rem',
        marginBottom: '1rem',
        marginRight: '1rem',
        maxWidth: '80%',
        alignSelf: 'flex-start',
    },
}
