import { uuid } from 'utils/String'

export const TAGS = [
    {
        label: 'weatherTag',
        value: 'weather',
    },
    {
        label: 'avalancheTag',
        value: 'avalanche',
    },
    {
        label: 'snowpackTag',
        value: 'snowpack',
    },
    {
        label: 'incidentTag',
        value: 'incident',
    },
    {
        label: 'generalTag',
        value: 'general',
    },
]

export const createNewImage = (locale = 'en') => ({
    credit: '',
    url: '',
    id: uuid(),
    caption: {
        [locale]: '',
    },
    tag: 'avalanche',
})

export const generateCloudinaryThumbnailUrl = (url, width, height) => {
    return url.replace('/upload/', `/upload/c_fill,w_${width},h_${height}/`)
}
