import React from 'react'

import { FormattedMessage } from 'react-intl'

import { formatDate } from 'utils/Date'
import { useDateFormat } from 'stores/UserStore'
import messages from 'services/intl/messageDefinitions'

export const Stat = ({ label, value, type, unit, time, size, labelStyles, valueStyles }) => {
    const storeFormat = useDateFormat()
    const dateFormat = time ? storeFormat + ', HH:mm' : storeFormat
    const labelSize = size === 'small' ? '10px' : '14px'
    const valueSize = size === 'small' ? '20px' : '25px'

    let formattedValue = value
    if (type === 'date') {
        formattedValue = formatDate(value, dateFormat)
    } else if (type === 'text' && value) {
        formattedValue = messages[value] ? <FormattedMessage {...messages[value]} /> : value
    } else if (type === 'tuple') {
        formattedValue = value.from + unit + ' - ' + value.to + unit
    }

    return (
        <div>
            <p style={{ ...styles.label, fontSize: labelSize, ...labelStyles }}>
                {messages[label] ? <FormattedMessage {...messages[label]} /> : label}
            </p>
            <h5 style={{ ...styles.value, fontSize: valueSize, ...valueStyles }}>{formattedValue}</h5>
        </div>
    )
}

const styles = {
    label: {
        color: 'var(--grey)',
        marginBottom: '0px',
        textTransform: 'capitalize',
        fontWeight: '600',
    },
    value: {
        marginTop: '0px',
        textTransform: 'capitalize',
        fontWeight: '400',
    },
}
