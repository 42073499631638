export const schema = (locale) => {
    return {
        public: false, // default
        problemType: null,
        weakLayer: {},
        distribution: null,
        sensitivity: null,
        size: {
            min: null,
            max: null,
        },
        typicalSize: null,
        likelihood: null,
        depth: {
            min: null,
            max: null,
        },
        aspectElevations: [],
        comment: {
            [locale]: null,
        },
        terrainTravelAdvice: [],
    }
}

export const HAZARD_LIKELIHOOD_MATRIX = {
    widespread: {
        unreactive: 'unlikely',
        stubborn: 'possible',
        reactive: 'veryLikely',
        touchy: 'certain',
    },
    specific: {
        unreactive: 'unlikely',
        stubborn: 'possible',
        reactive: 'likely',
        touchy: 'veryLikely',
    },
    isolated: {
        unreactive: 'unlikely',
        stubborn: 'unlikely',
        reactive: 'possible',
        touchy: 'likely',
    },
}

export const LIKELIHOODS = [
    'unlikely',
    'possible_unlikely',
    'possible',
    'likely_possible',
    'likely',
    'veryLikely_likely',
    'veryLikely',
    'certain_veryLikely',
    'certain',
]

export const HAZARDSTART = {
    size: {
        from: 1,
        to: 2,
    },
    likelihood: {
        from: 'unlikely',
        to: 'possible',
    },
    centroid: {
        size: 1.5,
        likelihood: 'possible_unlikely',
    },
}
