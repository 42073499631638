import React, { useCallback, useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { Button, Card, Space, Alert } from 'antd'

import { ImageFormSet } from 'components/Product/ImageUpload/ImageFormSet'
import { TranslatedTextField } from 'components/TextField/TranslatedTextField'
import {
    updateField,
    addTranslationToField,
    removeTranslationFromField,
    copyHeadlineToSms,
} from 'components/TextField/productHelpers'
import { SmsCardTitle } from 'components/Product/SmsCardTitle'
import messages from 'services/intl/messageDefinitions'

export const EditContentForm = ({ toggleContentEdit, item, apiPut }) => {
    const forecasterSet = item.userID
    const [updateProduct, setUpdateProduct] = useState(false)
    const [render, setRender] = useState(true)
    const [SmsField, setSmsField] = useState(null)

    const generateHandler = (field, mode) => (language, value) => {
        setUpdateProduct({ field, mode, language, value })
    }

    const handleHeadlineChange = generateHandler(['communications', 'headline'], 'update')
    const handleAddHeadlineTranslation = generateHandler(['communications', 'headline'], 'add')
    const handleRemoveHeadlineTranslation = generateHandler(['communications', 'headline'], 'remove')
    const handleSmsChange = generateHandler(['communications', 'sms'], 'update')
    const handleAddSmsTranslation = generateHandler(['communications', 'sms'], 'add')
    const handleRemoveSmsTranslation = generateHandler(['communications', 'sms'], 'remove')
    const handleMessageChange = generateHandler('message', 'update')
    const handleAddMessageTranslation = generateHandler('message', 'add')
    const handleRemoveMessageTranslation = generateHandler('message', 'remove')
    const handleCopyHeadlineToSms = generateHandler(null, 'copyHeadlineToSms')

    useEffect(() => {
        if (updateProduct) {
            setUpdateProduct(false)

            const { field, mode, language, value } = updateProduct
            let updatedProduct
            switch (mode) {
                case 'update':
                    updatedProduct = updateField(item, field, language, value)
                    break
                case 'add':
                    updatedProduct = addTranslationToField(item, field, language)
                    setRender(true)
                    break
                case 'remove':
                    updatedProduct = removeTranslationFromField(item, field, language)
                    setRender(true)
                    break
                case 'copyHeadlineToSms':
                    updatedProduct = copyHeadlineToSms(item)
                    setRender(true)
                    break
                default:
                    break
            }
            apiPut(updatedProduct)
        }
    }, [updateProduct, item])

    // Need to control when to render the SmsField so that it doesn't rerender every time text is updated
    // but does update when we add/remove new translations or copy from headline.
    const generateSmsField = () => {
        return (
            <TranslatedTextField
                handleChange={handleSmsChange}
                handleAddTranslation={handleAddSmsTranslation}
                handleRemoveTranslation={handleRemoveSmsTranslation}
                translations={item.communications.sms}
                plainText={true}
                characterLimit={140}
            />
        )
    }

    useEffect(() => {
        if (render && Object.keys(item).length > 0) {
            setSmsField(generateSmsField())
            setRender(false)
        }
    }, [item])

    return (
        <form>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <h1 style={styles.title}>
                    <FormattedMessage
                        {...messages.editProductContent}
                        values={{
                            product: item.name,
                        }}
                    />
                </h1>
                {!forecasterSet && (
                    <Alert
                        type="error"
                        message={
                            <FormattedMessage
                                id="setForecasterWarning"
                                defaultMessage="Please select a forecaster in the Setup before editing this product’s content"
                                description="Product content edit form"
                            />
                        }
                    />
                )}
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        display: 'flex',
                        pointerEvents: forecasterSet ? 'inherit' : 'none',
                        opacity: forecasterSet ? 1 : 0.5,
                    }}
                >
                    <Card title={<FormattedMessage {...messages.headline} />}>
                        <TranslatedTextField
                            handleChange={handleHeadlineChange}
                            handleAddTranslation={handleAddHeadlineTranslation}
                            handleRemoveTranslation={handleRemoveHeadlineTranslation}
                            translations={item.communications.headline}
                            plainText={false}
                            characterLimit={280}
                        />
                    </Card>
                    <Card title={<SmsCardTitle handleClick={handleCopyHeadlineToSms} />}>{!render && SmsField}</Card>
                    <Card title={<FormattedMessage {...messages.message} />}>
                        <TranslatedTextField
                            handleChange={handleMessageChange}
                            handleAddTranslation={handleAddMessageTranslation}
                            handleRemoveTranslation={handleRemoveMessageTranslation}
                            translations={item.message}
                            plainText={false}
                        />
                    </Card>
                    <Card title={<FormattedMessage {...messages.media} />}>
                        <ImageFormSet product={item} apiPut={apiPut} />
                    </Card>
                </Space>
                <div style={styles.buttonContainer}>
                    <Button type="primary" onClick={toggleContentEdit}>
                        <FormattedMessage {...messages.closeEditing} />
                    </Button>
                </div>
            </Space>
        </form>
    )
}

const styles = {
    buttonContainer: {
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'right',
    },
    title: {
        fontWeight: 300,
    },
}
