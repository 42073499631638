import { useEffect, useCallback, useRef } from 'react'

import MapboxDraw from '@mapbox/mapbox-gl-draw'
import bbox from '@turf/bbox'
import intersect from '@turf/intersect'
import { useThemeSwitcher } from 'react-css-theme-switcher'

import FreeDraw from 'utils/FreeDraw'
import { useLocale } from 'stores/UserStore'
import { useMap } from 'components/Map/Map'
import {
    createFeatureCollection,
    getPolygonLayers,
    darkSchemePolygonsColour,
    lightSchemePolygonsColour,
} from './mapHelpers'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

const MAP_BUFFER = 50

export const MultiProductPolygon = ({
    apiCall,
    polygons,
    useFilteredItems,
    useEditing,
    useActiveItem,
    useUpdateItem,
    useDraftItem,
    useUpdateDraftItem,
    useAssignedPolygons,
    productSource,
}) => {
    // Query params
    const locale = useLocale()

    // Map params
    const map = useMap()
    const polygonsSource = 'polygons'
    const productLayerId = productSource
    const productSourceName = productSource + 's'
    const drawToolRef = useRef()

    // Store params
    const assignedPolygons = useAssignedPolygons()
    const filteredForecasts = useFilteredItems()
    const isEditing = useEditing()
    const activeItem = useActiveItem()
    const draftItem = useDraftItem()
    const updateDraftItem = useUpdateDraftItem()
    const updateItem = useUpdateItem()

    // Secondary data
    const { currentTheme } = useThemeSwitcher()
    const polygonsColour = currentTheme === 'light' ? lightSchemePolygonsColour : darkSchemePolygonsColour

    // Set zoom extents on initial load
    // TODO: We should pull this out into a generic map component/hook
    const zoomExtents = useCallback((polygons) => {
        if (process.env.REACT_APP_INITIAL_ZOOM) {
            map.fitBounds(JSON.parse(process.env.REACT_APP_INITIAL_ZOOM))
        } else {
            let bounds = bbox(polygons)
            map.fitBounds(bounds, { padding: MAP_BUFFER })
        }
    }, [])

    useEffect(() => {
        zoomExtents(polygons)
    }, [])

    // Zooms to the active polygon
    useEffect(() => {
        if (activeItem && activeItem.polygons.length > 0 && !isEditing) {
            const activeItemPolygons = {
                ...polygons,
                features: polygons.features.filter((polygon) => {
                    return activeItem.polygons.includes(polygon.properties.id)
                }),
            }
            const bounds = bbox(activeItemPolygons)
            map.fitBounds(bounds, { padding: MAP_BUFFER })
        }

        if (!activeItem) {
            zoomExtents(polygons)
        }
    }, [activeItem, isEditing])

    // API calls
    const itemPutCall = useCallback(
        async (data, locale) => {
            const response = await apiCall(data, locale)
            updateItem(response.data)
        },
        [apiCall, updateItem]
    )

    const update = useCallback(
        (details) => {
            const data = { ...activeItem }
            for (let d in details) {
                const detail = details[d]
                data[detail.key] = detail.value
            }
            itemPutCall(data, locale)
        },
        [activeItem, itemPutCall, locale]
    )

    // Polygon selection
    const checkForExistingAssignment = useCallback(
        (polygons) => {
            const alreadyAssignedPolygons = assignedPolygons
                .filter((forecast) => forecast.id !== activeItem.id)
                .map((forecast) => forecast.polygons)
                .flat()

            return polygons.filter((polygon) => !alreadyAssignedPolygons.includes(polygon))
        },
        [activeItem, assignedPolygons]
    )

    const mergeClickedPolygonsWithExisting = useCallback(
        (ids) => {
            if (activeItem) {
                const polygons = new Set(activeItem.polygons)
                for (const id of ids) {
                    if (polygons.has(id)) {
                        polygons.delete(id)
                    } else {
                        polygons.add(id)
                    }
                }

                return Array.from(polygons)
            }
        },
        [activeItem]
    )

    const mergeLassoedPolygonsWithExisting = useCallback(
        (ids, mode) => {
            if (activeItem) {
                const polygons = new Set(activeItem.polygons)
                if (mode === 'add') {
                    for (const id of ids) {
                        if (!polygons.has(id)) {
                            polygons.add(id)
                        }
                    }
                } else {
                    for (const id of ids) {
                        if (polygons.has(id)) {
                            polygons.delete(id)
                        }
                    }
                }

                return Array.from(polygons)
            }
        },
        [activeItem]
    )

    const updateExistingOrDraftItem = useCallback(
        (polygons) => {
            if (activeItem && activeItem.id) {
                const updateData = {
                    key: 'polygons',
                    value: polygons,
                }
                update([updateData])
            } else {
                updateDraftItem({
                    ...draftItem,
                    polygons,
                })
            }
        },
        [activeItem, draftItem, update, updateDraftItem]
    )

    const clickPolygon = useCallback(
        (event) => {
            if (activeItem && isEditing) {
                const features = event.target.queryRenderedFeatures(event.point, {
                    layers: [polygonsSource, polygonsSource + '-line'],
                })
                const ids = new Set(features.map((feature) => feature.properties.id))

                if (ids.size > 0) {
                    updateExistingOrDraftItem(
                        mergeClickedPolygonsWithExisting(checkForExistingAssignment(Array.from(ids)))
                    )
                }
            }
        },
        [activeItem, checkForExistingAssignment, isEditing, mergeClickedPolygonsWithExisting, updateExistingOrDraftItem]
    )

    const lassoPolygon = useCallback(
        (event) => {
            const shiftHeld = window.event.shiftKey
            const mode = shiftHeld ? 'remove' : 'add'
            const [polygon] = event.features

            const ids = polygons.features
                .filter((feature) => intersect(feature, polygon))
                .map((feature) => feature.properties.id)

            updateExistingOrDraftItem(mergeLassoedPolygonsWithExisting(checkForExistingAssignment(ids), mode))

            drawToolRef.current.deleteAll()
        },
        [polygons.features, updateExistingOrDraftItem, mergeLassoedPolygonsWithExisting, checkForExistingAssignment]
    )

    // Update map layers based on filters
    useEffect(() => {
        if (map.getSource(productSourceName)) {
            map.getSource(productSourceName).setData(
                createFeatureCollection([...filteredForecasts, draftItem], polygons)
            )
        }
    }, [draftItem, filteredForecasts, map, polygons, productSourceName])

    useEffect(() => {
        drawToolRef.current = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
            },
            modes: Object.assign(MapboxDraw.modes, {
                draw_polygon: FreeDraw,
            }),
        })
        map.addControl(drawToolRef.current)

        return () => {
            // HACK: Check if the drawTool exists before removing it to prevent crash during navigation
            if (map.hasControl(drawToolRef.current)) {
                map.removeControl(drawToolRef.current)
            }
        }
    }, [])

    // Show/hide the lasso button
    useEffect(() => {
        const lassoButton = document.getElementsByClassName('mapbox-gl-draw_ctrl-draw-btn')
        if (!lassoButton) return null

        if (activeItem && isEditing) {
            lassoButton[0].classList.remove('inactive')
        } else {
            lassoButton[0].classList.add('inactive')
        }
    }, [activeItem, isEditing])

    // Add sources and layers for polygons
    useEffect(() => {
        // Adding the polygon source and layers to the map
        if (!map.getSource(polygonsSource)) {
            map.addSource(polygonsSource, { promoteId: 'id', type: 'geojson', data: polygons })
        }
        if (!map.getLayer(polygonsSource)) {
            map.addLayer({
                id: polygonsSource,
                source: polygonsSource,
                type: 'fill',
                paint: {
                    'fill-color': polygonsColour,
                },
            })

            map.addLayer({
                id: polygonsSource + '-line',
                source: polygonsSource,
                type: 'line',
                paint: {
                    'line-color': polygonsColour,
                    'line-width': 1.5,
                },
            })
        }

        // Adding the product source and layers to the map
        if (!map.getSource(productSourceName)) {
            map.addSource(productSourceName, {
                type: 'geojson',
                data: createFeatureCollection(filteredForecasts, polygons),
                promoteId: 'polygonId',
            })
        }
        for (const layer of getPolygonLayers(productSourceName, productLayerId)) {
            if (!map.getLayer(layer.id)) {
                map.addLayer(layer)
            }
        }
    }, [])

    useEffect(() => {
        // Add listeners only once to prevent repeated calls
        map.on('click', clickPolygon)
        map.on('draw.create', lassoPolygon)

        return () => {
            map.off('click', clickPolygon)
            map.off('draw.create', lassoPolygon)
        }
    }, [activeItem, clickPolygon, isEditing, map, lassoPolygon])

    // Highlight/unhighlight the active item
    useEffect(() => {
        if (activeItem) {
            const { polygons } = activeItem

            for (let id of polygons) {
                map.setFeatureState({ source: productSourceName, id }, { hover: true })
                map.setFeatureState({ source: productSourceName, id: id + '-line' }, { hover: true })
            }
        } else {
            map.removeFeatureState({ source: productSourceName })
        }
    }, [activeItem, map])

    return null
}
