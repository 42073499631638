import React from 'react'

import { FormattedMessage } from 'react-intl'
import { Card, Space } from 'antd'

import { useContent } from 'stores/ForecastStore'
import { EditScreenLayout } from 'components/EditScreenLayout/EditScreenLayout'
import { ImageFormSet } from 'components/Product/ImageUpload/ImageFormSet'
import messages from 'services/intl/messageDefinitions'

export const Media = ({ update, compressed }) => {
    const content = useContent()

    return (
        <>
            <EditScreenLayout showDaySelector={false} compressed={compressed}>
                <form style={styles.wrapper}>
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Card title={<FormattedMessage {...messages.media} />}>
                            <ImageFormSet product={content} apiPut={update} />
                        </Card>
                    </Space>
                </form>
            </EditScreenLayout>
        </>
    )
}

const styles = {
    wrapper: {
        margin: 'var(--s0)',
    },
}
