import React, { useEffect, useState, useRef } from 'react'

import { uuid } from 'utils/String'
import { useCharacterLimits, useContent, useDaysInView } from 'stores/ForecastStore'
import { EditScreenLayout } from 'components/EditScreenLayout/EditScreenLayout'
import { DayCardLayout } from 'components/DayCardLayout/DayCardLayout'
import { DayCard } from 'components/DayCard/DayCard'
import { TranslatedTextField } from 'components/TextField/TranslatedTextField'
import { noop } from 'utils/function'

export const Summary = ({
    sectionTitle,
    update,
    dayDetails,
    setReviewDrawerVisible,
    preview,
    compressed,
    colWidth,
}) => {
    const [cards, setCards] = useState([])
    const [updateFields, setUpdateFields] = useState(false)
    const [updateRemoveLanguage, setUpdateRemoveLanguage] = useState(false)
    const [cloneNext, setCloneNext] = useState(false)
    const [cloneAll, setCloneAll] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const content = useContent()
    const daysInView = useDaysInView()
    const characterLimit = useCharacterLimits()[sectionTitle] || null

    const actionHandlers = {
        cloneNext: (day) => setCloneNext(day.position),
        cloneAll: (day) => setCloneAll(day.position),
        reviewNote: () => reviewNote(),
        preview: () => preview(),
    }

    const reviewNote = () => {
        setReviewDrawerVisible(true)
    }

    // create array that contains objects that have all day info including content
    const structureDayData = (data) => {
        return dayDetails.map((detail, d) => {
            const dayIndex = data.findIndex((el) => el.position === detail.position)

            // TODO: change content to be based on language preference
            let dataDay = { content: { en: '' } }
            if (dayIndex !== -1) {
                dataDay = data[dayIndex]
            }

            return {
                // DayCard Setup
                value: d + 1,
                type: d === 0 ? 'Nowcast' : 'Forecast',
                date: detail.date,
                day: detail.day,

                // DayCard Content
                position: d,
                content: dataDay.content,
            }
        })
    }

    const generateDayCards = (structuredDays) => {
        return structuredDays.map((day, i) => {
            return (
                <DayCard
                    day={day}
                    key={uuid()}
                    editable={compressed ? false : true}
                    cardContent={
                        <TranslatedTextField
                            handleChange={(lang, html) => setUpdateFields({ lang, html, position: day.position })}
                            handleAddTranslation={(lang) =>
                                setUpdateFields({ lang, html: '', position: day.position, reload: true })
                            }
                            handleRemoveTranslation={(lang) =>
                                setUpdateRemoveLanguage({ lang, position: day.position })
                            }
                            translations={day.content}
                            plainText={false}
                            characterLimit={characterLimit || null}
                            translationRequired={i !== 0}
                        />
                    }
                    actionHandlers={actionHandlers}
                    dataProvider={noop}
                />
            )
        })
    }

    useEffect(() => {
        setDataLoaded(false)
    }, [daysInView])

    useEffect(() => {
        // this should only be truthy once
        if (dayDetails.length > 1 && !dataLoaded) {
            setDataLoaded(true)
            const list = content[sectionTitle] ? content[sectionTitle].days : []
            const structured = structureDayData(list)
            // setDays(structured)
            setCards(generateDayCards(structured))
        }
    }, [content, dayDetails])

    useEffect(() => {
        if (cloneNext !== false) {
            setCloneNext(false)

            let newDays = content[sectionTitle].days
            const dayToBeCloned = newDays[cloneNext]
            newDays[cloneNext + 1] = { ...dayToBeCloned, position: newDays[cloneNext + 1].position }

            // send API update
            update({ ...content, [sectionTitle]: { days: newDays } })

            // regenerate cards
            const structured = structureDayData(newDays)
            setCards(generateDayCards(structured))
        }
    }, [content, cloneNext])

    // Clone to All Days
    useEffect(() => {
        if (cloneAll !== false) {
            setCloneAll(false)

            let newDays = content[sectionTitle].days
            const dayToBeCloned = newDays[cloneAll]
            newDays = newDays.map((day) => {
                return { ...day, content: dayToBeCloned.content }
            })

            // send API update
            update({ ...content, [sectionTitle]: { days: newDays } })

            // regenerate cards
            const structured = structureDayData(newDays)
            setCards(generateDayCards(structured))
        }
    }, [content, cloneAll])

    // Update text content
    useEffect(() => {
        if (updateFields !== false) {
            setUpdateFields(false)
            const { lang, html, position, reload } = updateFields

            const newDays = content[sectionTitle].days.map((day, i) => {
                return i === position ? { ...day, content: { ...day.content, [lang]: html } } : day
            })

            update({ ...content, [sectionTitle]: { days: newDays } })

            if (reload) {
                const structured = structureDayData(newDays)
                setCards(generateDayCards(structured))
            }
        }
    }, [content, updateFields])

    // Remove translation
    useEffect(() => {
        if (updateRemoveLanguage !== false) {
            setUpdateRemoveLanguage(false)
            const { lang, position } = updateRemoveLanguage

            let newDays = content[sectionTitle].days
            const remainingTranslations = {}
            for (let key in newDays[position].content) {
                if (key !== lang) {
                    remainingTranslations[key] = newDays[position].content[key]
                }
            }
            newDays = newDays.map((day, i) => {
                return i === position ? { ...day, content: { ...remainingTranslations } } : day
            })

            update({ ...content, [sectionTitle]: { days: newDays } })
            const structured = structureDayData(newDays)
            setCards(generateDayCards(structured))
        }
    }, [content, updateRemoveLanguage])

    return (
        <>
            <EditScreenLayout dayDetails={dayDetails} compressed={compressed}>
                <DayCardLayout dayCards={cards} parentColWidth={colWidth} />
            </EditScreenLayout>
        </>
    )
}

const styles = {
    translationLabel: {
        marginBottom: '5px',
        marginLeft: '2px',
    },
    translationTag: {
        marginBottom: 'var(--s-3)',
        marginLeft: 'var(--s-1)',
    },
}
