import React, { useState } from 'react'

import { Row, Col, Modal, Checkbox } from 'antd'
import { ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'

import { getWindowDimensions } from 'utils/screen'
import { LIKELIHOODS, HAZARD_LIKELIHOOD_MATRIX } from 'utils/AvalancheProblems'
import messages from 'services/intl/messageDefinitions'
import HazardChart from 'components/AvalancheForecast/Content/AvalancheProblems/HazardChart'
import { AspectElevation } from 'components/AspectElevation/AspectElevation'
import { AvalancheProblemEditor } from 'components/AvalancheForecast/Content/AvalancheProblems/AvalancheProblemEditor/AvalancheProblemEditor'
import { HwyFxAvalancheProblemEditor } from './AvalancheProblemEditor/HwyFxAvalancheProblemEditor'
import { useEnhancedAccessibility } from 'stores/UserStore'

export const ProblemDisplay = ({
    content,
    problem,
    canToggle,
    handleUp,
    handleDown,
    handleDelete,
    publicToggle,
    handleUpdate,
    terrainTravelAdvice,
    isHwyFx = false,
}) => {
    const enhancedAccessibility = useEnhancedAccessibility()
    const likelihoodCentriod = LIKELIHOODS.indexOf(problem.likelihood)
    const [likelihoodRange, setLikelihoodRange] = useState({
        from: problem.likelihoodRange?.min,
        to: problem.likelihoodRange?.max,
    })
    const [sizeRange, setSizeRange] = useState({
        from: problem.size.min,
        to: problem.size.max,
    })
    const [centroidRange, setCentroidRange] = useState({
        size: problem.typicalSize,
        likelihood: HAZARD_LIKELIHOOD_MATRIX[problem.distribution]?.[problem.sensitivity] || null,
    })
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [publicValue, setPublicValue] = useState(problem.public)
    const windowDimensions = getWindowDimensions()

    const editorHeight = windowDimensions.height - 100 + 'px'

    const checkBoxHandler = (value) => {
        setPublicValue(!publicValue)
        publicToggle(value)
    }

    const showEditor = () => {
        setCreateModalVisible(true)
    }

    const problemTitle = problem.problemType ? (
        <FormattedMessage {...messages[problem.problemType]} />
    ) : (
        <FormattedMessage id="noProblemType" defaultMessage="No type" description="Avalanche problems form" />
    )

    const snapshotView = (
        <>
            <p>{problemTitle}</p>
        </>
    )

    const editorView = (
        <>
            <Row>
                <Col span={18}>
                    <Checkbox
                        disabled={!publicValue && !canToggle}
                        checked={publicValue}
                        onChange={checkBoxHandler}
                        style={styles.checkbox}
                    />
                    <a style={styles.header} onClick={showEditor} href="#">
                        {problemTitle}
                    </a>
                </Col>
                <Col span={6}>
                    <Row style={styles.iconsContainer}>
                        <Col style={styles.iconCol} span={24}>
                            {handleUp && (
                                <ArrowUpOutlined
                                    style={{ ...styles.icons, marginTop: 0 }}
                                    onClick={() => handleUp(problem)}
                                />
                            )}
                        </Col>
                        <Col style={styles.iconCol} span={24}>
                            <DeleteOutlined style={styles.icons} onClick={() => handleDelete(problem)} />
                        </Col>
                        <Col style={styles.iconCol} span={24}>
                            {handleDown && (
                                <ArrowDownOutlined
                                    style={{ ...styles.icons, marginBottom: 0 }}
                                    onClick={() => handleDown(problem)}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )

    return (
        <div style={styles.card}>
            {handleDelete ? editorView : snapshotView}
            <Row gutter={24}>
                <Col span={11} style={{ marginBottom: enhancedAccessibility ? 'var(--s0)' : null }}>
                    <AspectElevation
                        updateDraftItem={() => {}}
                        draftItem={{}}
                        aspectElevations={problem.aspectElevations}
                        colour={'#1890ff'}
                        compact={true}
                    />
                </Col>
                <Col style={styles.hazardCol} span={13}>
                    <div style={styles.cover}></div>
                    <HazardChart
                        value={{
                            size: sizeRange,
                            likelihood: likelihoodRange,
                            centroid: { size: problem.hazardSize, likelihood: problem.likelihood },
                        }}
                        marker={{ size: sizeRange, likelihood: likelihoodRange, centroid: centroidRange }}
                        onChange={() => {}}
                    />
                </Col>
            </Row>
            <Modal
                title={<FormattedMessage {...messages.avalancheProblem} />}
                centered
                visible={createModalVisible}
                maskClosable={false}
                footer={null}
                onCancel={() => setCreateModalVisible(false)}
                width={'95%'}
                bodyStyle={{ height: editorHeight, overflow: 'scroll' }}
            >
                {isHwyFx ? (
                    <HwyFxAvalancheProblemEditor
                        problemData={problem}
                        hideModal={() => setCreateModalVisible(false)}
                        content={content}
                        saveProblem={handleUpdate}
                        editing={true}
                        terrainTravelAdvice={terrainTravelAdvice}
                    />
                ) : (
                    <AvalancheProblemEditor
                        problemData={problem}
                        hideModal={() => setCreateModalVisible(false)}
                        content={content}
                        saveProblem={handleUpdate}
                        editing={true}
                        terrainTravelAdvice={terrainTravelAdvice}
                    />
                )}
            </Modal>
        </div>
    )
}

const styles = {
    card: {
        borderBottom: '1px solid var(--grey-light)',
        marginBottom: 'var(--s0)',
    },
    header: {
        marginBottom: 'var(--s0)',
        fontSize: '17px',
        display: 'block',
    },
    checkbox: {
        float: 'left',
        marginRight: '8px',
    },
    iconsContainer: {
        width: 'fitContent',
        float: 'right',
    },
    iconCol: {
        width: '5px',
    },
    hazardCol: {
        position: 'relative',
    },
    cover: {
        width: '100%',
        height: '100%',
        position: 'absolute',
    },
}
