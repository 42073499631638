import useSWR from 'swr'

import { useLocale } from 'stores/UserStore'
import { fetchHighwayLines } from 'services/HighwayLines'
import { HighwayForecastStore } from 'stores/HighwayForecastStore'

export const useHighwayLinesWithHazardRatings = () => {
    const language = useLocale()
    const key = ['highways/lines', language]
    const { data, isLoading, mutate } = useSWR(key, () => fetchHighwayLines(language))

    return {
        polylines: data?.data,
        isLoading,
        mutate,
    }
}
export const useFilteredHighwayLinesWithHazardRatings = () => {
    const { polylines, isLoading } = useHighwayLinesWithHazardRatings()
    const filters = HighwayForecastStore.useFilters()

    return isLoading ? [] : polylines[filters[0]]
}
