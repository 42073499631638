import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'

import { Row, Col, Button, Form, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'

import { schema } from 'utils/AvalancheProblems'
import { uuid } from 'utils/String'
import { HAZARD_LIKELIHOOD_MATRIX, LIKELIHOODS } from 'utils/AvalancheProblems'
import { getWindowDimensions } from 'utils/screen'
import messages from 'services/intl/messageDefinitions'
import { useLocale } from 'stores/UserStore'
import { AvalancheProblemForm } from './AvalancheProblemForm'
import HazardChart from 'components/AvalancheForecast/Content/AvalancheProblems/HazardChart'
import { AspectElevation } from 'components/AspectElevation/AspectElevation'
import { useTerrainSelectorForm } from 'components/SelectorForm/TerrainSelectorForm'
import { TranslatedTextField } from 'components/TextField/TranslatedTextField'
import { useCharacterLimits } from 'stores/ForecastStore'

const GRID_THRESHOLD = 1400

export const AvalancheProblemEditor = ({
    content,
    problemData = null,
    hideModal,
    saveProblem,
    editing,
    terrainTravelAdvice,
    currentDay,
}) => {
    // External
    const { Title } = Typography
    const [form] = Form.useForm()

    // Utils
    const locale = useLocale()
    const userInput = useRef()
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const actionButtonLabel = !editing ? messages.submitProblem : messages.updateProblem
    const characterLimit = useCharacterLimits().problemDiscussion

    // Errors and chart status
    const [weakLayerSelectError, setWeakLayerSelectError] = useState(false)
    const [aspectElevationsError, setAspectElevationsError] = useState(false)
    const [hazardChartError, setHazardChartError] = useState(false)
    const [disabledChart, setDisabledChart] = useState(true)
    const [guidanceEnabled, setGuidanceEnabled] = useState(false)

    // Problem State
    const [problem, setProblem] = useState(problemData || schema(locale))
    const [userHazard, setUserHazard] = useState()
    const [selectedStatements, setSelectedStatements] = useState(terrainTravelAdvice)
    const [translations, setTranslations] = useState(problem.comment)
    const [depth, setDepth] = useState({
        min: problem.depth.from,
        max: problem.depth.to,
    })
    const [likelihoodRange, setLikelihoodRange] = useState({
        from: problem.likelihood ? problem.likelihoodRange.min : undefined,
        to: problem.likelihood ? problem.likelihoodRange.max : undefined,
    })
    const [sizeRange, setSizeRange] = useState({
        from: problem.typicalSize ? problem.typicalSize - 0.5 : undefined,
        to: problem.typicalSize ? problem.typicalSize + 0.5 : undefined,
    })
    const [centroidRange, setCentroidRange] = useState({
        size: problem.typicalSize || undefined,
        likelihood: problem.likelihood || undefined,
    })
    const [hazard, setHazard] = useState({
        size: sizeRange,
        likelihood: likelihoodRange,
        centroid: centroidRange,
    })

    form.setFieldsValue({
        problemType: problem.problemType,
        distribution: problem.distribution,
        weakLayer: problem.weakLayer,
        typicalSize: problem.typicalSize,
        sensitivity: problem.sensitivity,
    })

    const statementSelectHandler = (newSelectedStatements) => {
        setSelectedStatements([...newSelectedStatements].map((statement) => statement.key))
    }

    const { SelectorForm, clearStatementInput } = useTerrainSelectorForm(
        selectedStatements,
        statementSelectHandler,
        '80vh',
        8
    )

    const updateAspectsAndElevations = (list) => {
        setProblem({
            ...problem,
            aspectElevations: list,
        })
    }

    const calculateNewLikelihood = (newCentroidLikelihood) => {
        const newCentroidLikelihoodIndex = LIKELIHOODS.indexOf(newCentroidLikelihood)

        const newLikelihoodFromIndex = newCentroidLikelihoodIndex - 1
        const newLikelihoodToIndex = newCentroidLikelihoodIndex + 1

        return {
            from: LIKELIHOODS[newLikelihoodFromIndex > -1 ? newLikelihoodFromIndex : 0],
            to: LIKELIHOODS[newLikelihoodToIndex < 9 ? newLikelihoodToIndex : 8],
        }
    }

    const updateSizeRange = (value) => {
        setSizeRange({
            from: value == 1 ? value : value - 0.5,
            to: value == 5 ? 5 : value + 0.5,
        })
    }

    const generateMarkerCentroid = () => {
        setCentroidRange({
            size: problem.typicalSize,
            likelihood: HAZARD_LIKELIHOOD_MATRIX[problem.distribution][problem.sensitivity],
        })
        setGuidanceEnabled(true)
        setUserHazard(generateUserHazard())
        setDisabledChart(false)
    }

    const acceptHazardEval = () => {
        setUserHazard(hazard)
        userInput.current = hazard
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        // check if likelihood needs to be updated
        if (problem.sensitivity && problem.distribution) {
            const newLikelihood = HAZARD_LIKELIHOOD_MATRIX[problem.distribution][problem.sensitivity]
            if (newLikelihood !== problem.likelihood) {
                setLikelihoodRange(calculateNewLikelihood(newLikelihood))
            }
        }

        // check if centroid needs to be updated
        if (problem.sensitivity && problem.distribution && problem.typicalSize) {
            generateMarkerCentroid()
        }
    }, [problem])

    useEffect(() => {
        if (sizeRange.from && likelihoodRange.from && centroidRange.size) {
            setHazard({ size: sizeRange, likelihood: likelihoodRange, centroid: centroidRange })
        }
    }, [sizeRange, likelihoodRange, centroidRange])

    useEffect(() => {
        if (sizeRange.from && problem.sensitivity && problem.distribution) {
            generateMarkerCentroid()
        }
    }, [sizeRange])

    const generateUserHazard = () => {
        return {
            size: {
                from: problem.size.min ? problem.size.min : 1,
                to: problem.size.max ? problem.size.max : 2,
            },
            likelihood: problem.likelihood ? likelihoodRange : { from: 'unlikely', to: 'possible' },
            centroid: {
                size: problem.hazardSize ? problem.hazardSize : 1.5,
                likelihood: problem.likelihood ? problem.likelihood : 'possible_unlikely',
            },
        }
    }

    const validateProblem = () => {
        let valid = true
        let pendingProblem
        userInput.current = userInput.current ? userInput.current : userHazard
        if (userInput.current) {
            pendingProblem = {
                ...problem,
                size: {
                    min: userInput.current.size.from,
                    max: userInput.current.size.to,
                },
                depth: {
                    from: depth.min,
                    to: depth.max,
                },
                likelihood: userInput.current.centroid.likelihood,
                likelihoodRange: { min: userInput.current.likelihood.from, max: userInput.current.likelihood.to },
                hazardSize: userInput.current.centroid.size,
                typicalSize: hazard.centroid.size,
                public: true,
                comment: {
                    ...translations,
                },
                id: uuid(),
            }
        } else {
            valid = false
        }

        form.validateFields()

        if (!problem.problemType || !problem.distribution || !problem.typicalSize || !problem.sensitivity) {
            valid = false
        }

        if (!problem.weakLayer.id) {
            setWeakLayerSelectError(true)
            valid = false
        } else {
            setWeakLayerSelectError(false)
        }

        if (!problem.aspectElevations.length) {
            setAspectElevationsError(true)
            valid = false
        } else {
            setAspectElevationsError(false)
        }

        if (!userInput.current) {
            setHazardChartError(true)
            valid = false
        } else {
            setHazardChartError(false)
        }

        if (valid) {
            try {
                saveProblem(pendingProblem, selectedStatements)
            } catch (e) {
                console.log(e)
            }
            hideModal()
        }
    }

    const handleCancel = () => {
        hideModal()
    }

    const addNewTranslation = (language) => {
        setTranslations({
            ...translations,
            [language]: '',
        })
    }

    const removeTranslation = (language) => {
        const newTranslations = { ...translations }
        delete newTranslations[language]
        setTranslations(newTranslations)
    }

    const handleEditorChange = (language, value) => {
        setTranslations({
            ...translations,
            [language]: value,
        })
    }

    const HazardChartField = ({ hazardValue, userValue }) => {
        const value = useMemo(() => {
            return hazardValue
        }, [hazardValue])

        const onChange = useCallback((val) => {
            if (val.centroid.size < val.size.from) {
                userInput.current = undefined
            } else {
                userInput.current = val
            }
            setUserHazard(val)
        }, [])

        return (
            <>
                {hazardChartError && (
                    <p style={styles.error}>
                        <FormattedMessage {...messages.hazardRequired} />
                    </p>
                )}
                <HazardChart
                    value={userInput.current ? userInput.current : userValue}
                    marker={value}
                    onChange={onChange}
                    disabled={disabledChart}
                />
            </>
        )
    }

    return (
        <div style={styles.container}>
            <Row>
                <Col span={windowDimensions.width < GRID_THRESHOLD ? 12 : 8}>
                    <AvalancheProblemForm
                        form={form}
                        content={content}
                        initialValues={problem}
                        updateProblem={setProblem}
                        setSize={updateSizeRange}
                        problem={problem}
                        depth={depth}
                        setDepth={setDepth}
                        weakLayerSelectError={weakLayerSelectError}
                    />
                    {windowDimensions.width < GRID_THRESHOLD && (
                        <Row gutter={8}>
                            <Col span={12}>
                                <AspectElevation
                                    updateDraftItem={(item) => {
                                        updateAspectsAndElevations(item.aspectElevations)
                                    }}
                                    draftItem={{}}
                                    aspectElevations={problem.aspectElevations}
                                    update={() => {}}
                                    create={() => {}}
                                    colour={'#1890ff'}
                                    errorStatus={aspectElevationsError}
                                />
                            </Col>
                            <Col span={12}>
                                <HazardChartField
                                    hazardValue={hazard}
                                    userValue={userHazard}
                                    updateProblem={setProblem}
                                    currentProblem={problem}
                                />
                            </Col>
                        </Row>
                    )}
                    <TranslatedTextField
                        handleChange={handleEditorChange}
                        handleAddTranslation={addNewTranslation}
                        handleRemoveTranslation={removeTranslation}
                        translations={translations}
                        plainText={false}
                        translationRequired={currentDay?.position !== 0}
                        characterLimit={characterLimit}
                    />
                    <div data-test={'editors'}>
                        <Button
                            style={styles.rec}
                            onClick={acceptHazardEval}
                            disabled={!guidanceEnabled}
                            data-test={'guidanceButton'}
                        >
                            <FormattedMessage {...messages.guidance} />
                        </Button>
                    </div>
                </Col>
                <Col style={styles.visuals} span={windowDimensions.width < GRID_THRESHOLD ? 1 : 9}>
                    {windowDimensions.width > GRID_THRESHOLD && (
                        <Row>
                            <Col span={24}>
                                <AspectElevation
                                    updateDraftItem={(item) => {
                                        updateAspectsAndElevations(item.aspectElevations)
                                    }}
                                    draftItem={{}}
                                    aspectElevations={problem.aspectElevations}
                                    update={() => {}}
                                    create={() => {}}
                                    colour={'#1890ff'}
                                    errorStatus={aspectElevationsError}
                                />
                            </Col>
                            <Col span={24}>
                                <HazardChartField
                                    hazardValue={hazard}
                                    userValue={userHazard}
                                    updateProblem={setProblem}
                                    currentProblem={problem}
                                />
                            </Col>
                        </Row>
                    )}
                </Col>
                <Col span={windowDimensions.width < GRID_THRESHOLD ? 11 : 7}>
                    <Title style={styles.terrainAndTravelAdvice} level={4}>
                        <FormattedMessage {...messages.terrainAndTravelAdvice} />
                    </Title>
                    {SelectorForm}
                </Col>
            </Row>
            <div style={styles.footer}>
                <Button onClick={validateProblem} type="primary" data-test={'saveProblemButton'}>
                    <FormattedMessage {...actionButtonLabel} />
                </Button>
                <Button onClick={handleCancel} style={styles.cancel}>
                    <FormattedMessage {...messages.cancel} />
                </Button>
            </div>
        </div>
    )
}

const styles = {
    error: {
        color: 'var(--red)',
        textAlign: 'center',
    },
    errorRight: {
        color: 'var(--red)',
    },
    container: {
        paddingBottom: '75px',
    },
    visuals: {
        padding: ' var(--s0)',
    },
    rec: {
        float: 'right',
        marginTop: '-33px', // needed to align the guidance button with the  add language button
    },
    footer: {
        justifyContent: 'flex-end',
        display: 'flex',
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        background: 'var(--background-colour)',
        height: '60px',
        borderTop: '1px solid var(--grey-light)',
        padding: '15px 10px 10px',
        right: '0px',
    },
    cancel: {
        marginLeft: 'var(--s0)',
    },
    publicLabel: {
        marginBottom: '3px',
        textTransform: 'capitalize',
    },
    toggleContainers: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    terrainAndTravelAdvice: {
        fontWeight: 400,
    },
    translationLabel: {
        marginBottom: '5px',
        marginLeft: '2px',
    },
    translationTag: {
        marginBottom: 'var(--s-3)',
        marginLeft: 'var(--s-1)',
    },
    roseToggle: {
        marginBottom: 'var(--s-2)',
    },
}
