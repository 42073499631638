import React, { useEffect, useCallback } from 'react'

import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { FILTER_OPTIONS } from 'utils/Product'
import { fetchPolygons } from 'services/Polygons'
import messages from 'services/intl/messageDefinitions'
import { useLocale } from 'stores/UserStore'
import { HighwayForecastStore } from 'stores/HighwayForecastStore'
import { Map, WithMapReady } from 'components/Map/Map'
import { Sidebar } from 'components/Sidebar/Sidebar'
import { Details } from 'components/Highways/HighwayForecasts/Details'
import { Summary } from 'components/Product/Summary'
import { CreateForm } from 'components/Highways/HighwayForecasts/CreateForm'
import { TwoColLayoutPrimaryRight } from 'components/TwoColumnLayout/TwoColLayoutPrimaryRight'
import { UnassignedPolygons } from 'components/Map/UnassignedPolygons'
import { fetchHighwayForecasts, putHighwayForecast, cloneAllHighwayForecasts } from 'services/HighwayForecasts'
import { MapStatusBar } from 'components/MapStatusBar/MapStatusBar'
import { ProductStatusBarText } from 'components/Product/ProductStatusBarText'
import { CloneAllButton } from 'components/Product/CloneAllButton'
import { Accessibility } from 'components/Accessibility'
import { MultipleProductsTable } from 'components/Map/Accessibility/MultipleProductsTable'
import { Polylines } from 'components/Map/Polylines'
import { MultiProductPolygon } from 'components/Map/MultiProductPolygon'
import { useHighwayLinesWithHazardRatings, useFilteredHighwayLinesWithHazardRatings } from 'hooks/highwayLines'

export const HighwayForecasts = () => {
    // Query and API params
    const [queryParams] = useSearchParams()
    const locale = useLocale()
    const intl = useIntl()
    const queryID = queryParams.get('focus') || ''
    const { mutate } = useHighwayLinesWithHazardRatings()
    const filteredPolylines = useFilteredHighwayLinesWithHazardRatings()

    // Store
    const highwayForecasts = HighwayForecastStore.useProducts()
    const updateHighwayForecasts = HighwayForecastStore.useUpdateProducts()
    const polygons = HighwayForecastStore.usePolygons()
    const updatePolygons = HighwayForecastStore.useUpdatePolygons()
    const filters = HighwayForecastStore.useFilters()
    const addFilter = HighwayForecastStore.useAddFilter()
    const activeHighwayForecast = HighwayForecastStore.useActiveProduct()
    const setActiveHighwayForecastId = HighwayForecastStore.useSetActiveProductId()

    // Secondary data
    const unassignedCount = HighwayForecastStore.useUnassignedPolygons()
    const disableSetupButton = filters[0] !== 'draft'

    useEffect(() => {
        document.title = intl.formatMessage(messages.hwyForecast)
    }, [])

    // API calls
    const getHighwayForecasts = useCallback(async () => {
        const response = await fetchHighwayForecasts(locale)
        updateHighwayForecasts(response.data)
        mutate()
    }, [])

    const getPolygons = useCallback(async () => {
        const response = await fetchPolygons(locale)
        updatePolygons(response.data)
    }, [])

    useEffect(() => {
        // Fetch products, and polygons on page load
        getHighwayForecasts()
        getPolygons()
    }, [])

    // Set active product and filters
    useEffect(() => {
        if (queryID) {
            setActiveHighwayForecastId(queryID)
        }
        filters.forEach((filter) => {
            // default filter
            addFilter(filter)
        })
        if (activeHighwayForecast && activeHighwayForecast.id === queryID) {
            addFilter(activeHighwayForecast.status)
        }
    }, [highwayForecasts])

    const standardA11yComponent = (
        <>
            <Map locale={locale}>
                <WithMapReady>
                    {polygons && polygons.features && (
                        <MultiProductPolygon
                            useEditing={HighwayForecastStore.useEditingProductSetup}
                            useActiveItem={HighwayForecastStore.useActiveProduct}
                            useUpdateItem={HighwayForecastStore.useUpdateProduct}
                            apiCall={putHighwayForecast}
                            polygons={polygons}
                            useDraftItem={HighwayForecastStore.useDraftProduct}
                            useUpdateDraftItem={HighwayForecastStore.useUpdateDraftProduct}
                            useFilteredItems={HighwayForecastStore.useFilteredProducts}
                            useAssignedPolygons={HighwayForecastStore.useAssignedPolygons}
                            productSource="highwayForecast"
                        />
                    )}
                    {filteredPolylines && filteredPolylines.features && <Polylines polylines={filteredPolylines} />}
                </WithMapReady>
            </Map>
            <UnassignedPolygons unassignedCount={unassignedCount} />
            {activeHighwayForecast && (
                <MapStatusBar
                    product={activeHighwayForecast}
                    text={<ProductStatusBarText product={activeHighwayForecast} />}
                />
            )}
        </>
    )

    const enhancedA11yComponent = polygons && polygons.features && (
        <MultipleProductsTable
            useEditing={HighwayForecastStore.useEditingProductSetup}
            unassignedCount={unassignedCount}
            useActiveItem={HighwayForecastStore.useActiveProduct}
            useProducts={HighwayForecastStore.useFilteredProducts}
            useUpdateItem={HighwayForecastStore.useUpdateProduct}
            putRequest={putHighwayForecast}
            polygons={polygons}
            useDraftItem={HighwayForecastStore.useDraftProduct}
            useUpdateDraftItem={HighwayForecastStore.useUpdateDraftProduct}
        />
    )

    return (
        <TwoColLayoutPrimaryRight
            left={
                <Accessibility
                    standardA11yComponent={standardA11yComponent}
                    enhancedA11yComponent={enhancedA11yComponent}
                />
            }
            right={
                <Sidebar
                    title={'hwyForecast'}
                    emptyMessage={'noHighwayForecastsFound'}
                    Details={Details}
                    Summary={Summary}
                    dataProvider={HighwayForecastStore.useFilteredProducts}
                    filterProvider={HighwayForecastStore.useFilters}
                    listData={highwayForecasts}
                    filterOptions={FILTER_OPTIONS}
                    activeFilters={filters}
                    useAddFilter={HighwayForecastStore.useAddFilter}
                    useRemoveFilter={HighwayForecastStore.useRemoveFilter}
                    useClearActiveID={HighwayForecastStore.useClearActiveProductId}
                    createLabel={'createHighwayForecast'}
                    disableSetupButton={disableSetupButton}
                    useSetActiveId={HighwayForecastStore.useSetActiveProductId}
                    useSetEditing={HighwayForecastStore.useSetEditingProductSetup}
                    useSetEditingContent={HighwayForecastStore.useSetEditingProductContent}
                    useResetDraft={HighwayForecastStore.useResetDraftProduct}
                    CreateForm={CreateForm}
                    getData={getHighwayForecasts}
                    useActiveItem={HighwayForecastStore.useActiveProduct}
                    radioStyleFiltering={true}
                    buttons={
                        <CloneAllButton
                            getData={getHighwayForecasts}
                            useFilters={HighwayForecastStore.useFilters}
                            useAddFilter={HighwayForecastStore.useAddFilter}
                            cloneAllApiCall={cloneAllHighwayForecasts}
                            buttonId={'cloneAllButton'}
                        />
                    }
                ></Sidebar>
            }
        />
    )
}
