export const MENU_ITEMS = {
    weatherSummary: {
        progressPortion: 15,
    },
    snowpackSummary: {
        progressPortion: 15,
    },
    avalancheSummary: {
        progressPortion: 15,
    },
    avalancheProblems: {
        progressPortion: 20,
    },
    highwayHazards: {
        progressPortion: 5,
    },
    confidence: {
        progressPortion: 5,
    },
    media: {
        progressPortion: 5,
    },
    communications: {
        progressPortion: 10,
    },
    review: {
        progressPortion: 10,
    },
}

export const HIGHWAY_HAZARD_OPTIONS = new Map([
    ['none', '#808080'],
    ['noRating', '#FFFFFF'],
    ['notice', '#008000'],
    ['caution', '#FFFF00'],
    ['warning', '#FF0000'],
])

export const DEFAULT_HIGHWAY_HAZARD_VALUE = 'none'

export const REVIEW_STATUS_OPTIONS = [
    {
        label: 'highPriority',
        value: 'highPriority',
        colour: '#FF0000',
        order: 1,
    },
    {
        label: 'lowPriority',
        value: 'lowPriority',
        colour: '#FFFF00',
        order: 2,
        textColour: '#000000',
    },
    {
        label: 'notRequired',
        value: 'notRequired',
        colour: '',
        order: 4,
    },
    {
        label: 'reviewed',
        value: 'reviewed',
        colour: '#00FF00',
        order: 3,
        textColour: '#000000',
    },
]

export const getOrderOfReviewStatus = (status) => {
    return status ? REVIEW_STATUS_OPTIONS.find((option) => option.value === status)?.order : 4
}
export const getColourOfReviewStatus = (status) => {
    return status ? REVIEW_STATUS_OPTIONS.find((option) => option.value === status)?.colour : ''
}
export const getReviewStatusTextColour = (status) => {
    return status ? REVIEW_STATUS_OPTIONS.find((option) => option.value === status)?.textColour : ''
}

export const progressToPercent = (progress) => {
    return progress
        ? progress.reduce((sum, item) => {
              return sum + (item.complete ? MENU_ITEMS[item.element].progressPortion : 0)
          }, 0)
        : 0
}

export const avalancheProblemSchema = (locale) => {
    return {
        public: false, // default
        problemType: null,
        weakLayer: {},
        distribution: null,
        sensitivity: null,
        size: {
            min: null,
            max: null,
        },
        typicalSize: null,
        likelihood: null,
        likelihoodHighways: null, // redundant property manually set via drop-down per CAIC request
        depth: {
            min: null,
            max: null,
        },
        aspectElevations: [],
        comment: {
            [locale]: null,
        },
        terrainTravelAdvice: [],
        threshold: null,
        effectOnRoadway: null,
    }
}
