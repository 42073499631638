import React, { useState, useEffect, useCallback } from 'react'

import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import { FormattedMessage } from 'react-intl'
import { Modal } from 'antd'

import { noop } from 'utils/function'
import { clearHtmlFormatting } from 'utils/String'
import messages from 'services/intl/messageDefinitions'
import { MenuBar } from './MenuBar'
import { PlainTextMenu } from './PlainTextMenu'
import './RichTextEditor.css'

export const RichTextEditor = ({
    title,
    content,
    handleEditorStateChange,
    dataProvider,
    plainText,
    characterLimit,
    removeHandler,
}) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [editorContent, setEditorContent] = useState(content)
    const [deleteLanguageModalVisible, setDeleteLanguageModalVisible] = useState(false)
    const [contentTouched, setContentTouched] = useState(false)
    const data = dataProvider ? dataProvider() : noop()

    const editor = useEditor({
        extensions: [
            StarterKit,
            Link.configure({
                openOnClick: false,
            }),
        ],
        onUpdate({ editor }) {
            setContentTouched(true)
            setEditorContent(editor.getHTML())
        },
        editorProps: {
            transformPastedHTML(html) {
                return plainText ? clearHtmlFormatting(html) : html
            },
        },
        disableEditing: true,
        content: editorContent,
    })

    const modalEditor = useEditor({
        extensions: [
            StarterKit,
            Link.configure({
                openOnClick: false,
            }),
        ],
        onUpdate({ editor }) {
            setContentTouched(true)
            setEditorContent(editor.getHTML())
        },
        editorProps: {
            transformPastedHTML(html) {
                return plainText ? clearHtmlFormatting(html) : html
            },
        },
        content: editorContent,
    })

    const DeleteTranslationConfirmationModal = ({ visible, removeHandler, setVisible }) => {
        const handleOk = () => {
            removeHandler(editorContent, data)
            setVisible(false)
        }

        return (
            <Modal
                title={<FormattedMessage {...messages.warning} />}
                visible={visible}
                onOk={() => handleOk()}
                onCancel={() => setVisible(false)}
                okText={<FormattedMessage {...messages.delete} />}
                cancelText={<FormattedMessage {...messages.cancel} />}
            >
                <FormattedMessage {...messages.deleteTranslationConfirmation} />{' '}
            </Modal>
        )
    }

    // useEffect(() => {
    //     if(editor) {
    //         editor.off("update");
    //         editor.on("update", ({ editor: updatedEditor }) => setEditorContent(updatedEditor.getHTML()));
    //     }
    // }, [editor, setEditorContent]);

    // Keep Both editors in sync
    useEffect(() => {
        if (editor && modalVisible) {
            editor.commands.setContent(editorContent)
        } else if (editor) {
            modalEditor.commands.setContent(editorContent)
        }
        // setContentTouched(true)
    }, [editorContent])

    // clear the editor when the post button is clicked (only applies to weak layers)
    // This is a bit of a hack - this will break if there's anywhere that we click
    // 'post' with a plaintext editor. So far that doesn't exist in the app.
    // 6 characters is used here because it will always be less than a <p> tag: '<p></p>'
    useEffect(() => {
        if (editor && content.length < 6 && !plainText) {
            editor.commands.clearContent()
        }
    }, [content])

    // debounce
    useEffect(() => {
        const handler = setTimeout(() => {
            if (contentTouched) {
                handleEditorStateChange(editorContent, data)
                setContentTouched(false)
            }
        }, 700)

        return () => {
            clearTimeout(handler)
        }
    }, [editorContent])

    const setLink = useCallback(() => {
        const previousUrl = editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
            return
        }

        // empty
        if (url === '') {
            editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run()

            return
        }

        // update link
        editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: url })
            .run()
    }, [editor])

    return (
        <>
            <Modal
                title={title ? title : <FormattedMessage {...messages.expandedEditor} />}
                centered
                visible={modalVisible}
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
                okText="Close"
                cancelButtonProps={{ style: { display: 'none' } }}
                width={'90%'}
            >
                <div style={styles.modalEditor}>
                    {plainText ? (
                        <PlainTextMenu
                            setLink={setLink}
                            editor={modalEditor}
                            setModalVisible={setModalVisible}
                            setDeleteModalVisible={setDeleteLanguageModalVisible}
                            expandable={false}
                            characterLimit={characterLimit}
                            removeHandler={removeHandler}
                        />
                    ) : (
                        <MenuBar
                            setLink={setLink}
                            editor={modalEditor}
                            setModalVisible={setModalVisible}
                            setDeleteModalVisible={setDeleteLanguageModalVisible}
                            expandable={true}
                            removeHandler={removeHandler}
                            characterLimit={characterLimit}
                        />
                    )}
                    <EditorContent editor={modalEditor} />
                </div>
            </Modal>
            <DeleteTranslationConfirmationModal
                visible={deleteLanguageModalVisible}
                removeHandler={removeHandler}
                setVisible={setDeleteLanguageModalVisible}
            />
            <div style={styles.editor}>
                {plainText ? (
                    <PlainTextMenu
                        setLink={setLink}
                        editor={editor}
                        setModalVisible={setModalVisible}
                        setDeleteModalVisible={setDeleteLanguageModalVisible}
                        expandable={true}
                        characterLimit={characterLimit}
                        removeHandler={removeHandler}
                    />
                ) : (
                    <MenuBar
                        setLink={setLink}
                        editor={editor}
                        setModalVisible={setModalVisible}
                        setDeleteModalVisible={setDeleteLanguageModalVisible}
                        expandable={true}
                        removeHandler={removeHandler}
                        characterLimit={characterLimit}
                    />
                )}
                <EditorContent editor={editor} />
            </div>
        </>
    )
}

const styles = {
    editor: {
        border: '1px solid var(--grey-light)',
        borderRadius: 'var(--radius)',
        marginBottom: 'var(--s1)',
        overflow: 'auto',
        width: '100%',
    },
    modalEditor: {
        border: '1px solid var(--grey-light)',
        borderRadius: 'var(--radius)',
        marginBottom: 'var(--s1)',
        minHeight: '500px',
        overflow: 'scroll',
        width: '100%',
    },
}
