import React, { useEffect, useState } from 'react'

import { Collapse, Table } from 'antd'
import { FormattedMessage } from 'react-intl'

import { hexToRGB } from 'utils/Color'
import messages from 'services/intl/messageDefinitions'

export const PolygonList = ({ polygonList, colour, usePolygons }) => {
    const { Panel } = Collapse
    const polygons = usePolygons()
    const [selectedPolygons, setSelectedPolygons] = useState([])

    useEffect(() => {
        setSelectedPolygons(
            polygonList.map((polygonId) => {
                return polygons.features?.find((polygon) => polygon.properties.id === polygonId)
            })
        )
    }, [polygonList, polygons])

    const polygonData = selectedPolygons.map((poly, i) => {
        return {
            key: i,
            id: poly?.properties.polygon_number,
            name: poly?.properties.polygon_name,
            mountainRange: poly?.properties.mountain_range,
        }
    })
    const filteredPolygonData = polygonData.filter((polygon) => polygon.id !== undefined)

    const columns = [
        {
            title: <FormattedMessage {...messages['polygonList.titles.id']} />,
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: <FormattedMessage {...messages['polygonList.titles.name']} />,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <FormattedMessage {...messages['polygonList.titles.mountainRange']} />,
            dataIndex: 'mountainRange',
            key: 'mountainRange',
        },
    ]

    const locale = {
        emptyText: (
            <span>
                <p>
                    <FormattedMessage {...messages.noPolygonDetails} />
                </p>
            </span>
        ),
    }

    return (
        <Collapse style={{ background: hexToRGB(colour, 0.35) }} defaultActiveKey={[]}>
            <Panel
                header={
                    <div>
                        <FormattedMessage {...messages.polygons} values={{ value: polygonData.length }} />
                    </div>
                }
                key="1"
            >
                <Table
                    locale={polygonData.length ? locale : undefined}
                    pagination={false}
                    dataSource={filteredPolygonData}
                    columns={columns}
                />
            </Panel>
        </Collapse>
    )
}
