import { Checkbox, Row } from 'antd'

import { useContent } from 'stores/ForecastStore'

import './MenuItem.css'

export const MenuItem = ({ label, checked, selected, view, clickHandler, Badge, update }) => {
    const content = useContent()

    const handleClick = () => {
        clickHandler(view)
    }

    const updateForecastProgress = () => {
        const progress = [...content.progress].map((element) => {
            return element.element === view ? { ...element, complete: !element.complete } : element
        })
        return { ...content, progress }
    }

    const handleCheckClick = (event) => {
        // stop propagation so the checkbox doesn't trigger the click handler on the menu item
        event.stopPropagation()

        // generate new content with updated progress
        const newAvalancheForecast = updateForecastProgress()
        update(newAvalancheForecast)
    }

    return (
        <div
            onClick={handleClick}
            style={selected ? styles.activeContainer : styles.container}
            data-test={`${view}MenuTab`}
        >
            <Checkbox style={styles.checkbox} checked={checked} onClick={handleCheckClick} />

            <Row style={styles.menuAndBadge}>
                <h3 style={styles.label}>{label}</h3>
                {Badge}
            </Row>
        </div>
    )
}

const styles = {
    container: {
        padding: '0.7rem var(--s0) 0.5rem',
        borderTop: '1px solid var(--grey-lighter)',
        borderBottom: '1px solid var(--grey-lighter)',
        cursor: 'pointer',
        background: 'var(--background-colour)',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    activeContainer: {
        padding: '0.7rem var(--s0) 0.5rem',
        borderTop: '1px solid var(--grey-lighter)',
        borderBottom: '1px solid var(--grey-lighter)',
        borderRight: '3px solid var(--blue-lt)',
        cursor: 'pointer',
        background: 'var(--blue-lighter)',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    label: {
        marginLeft: 'var(--s0)',
        fontWeight: '300',
    },
    checkbox: {
        marginTop: 'var(--s-5)',
    },
    menuAndBadge: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
}
