import { useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { Card, Row, Select, Space, Form, Col, DatePicker, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import { useContent } from 'stores/ForecastStore'
import { EditScreenLayout } from 'components/EditScreenLayout/EditScreenLayout'
import messages from 'services/intl/messageDefinitions'
import { DEFAULT_HIGHWAY_HAZARD_VALUE, HIGHWAY_HAZARD_OPTIONS } from 'utils/HighwayContent'
import { useDateFormat } from 'stores/UserStore'
import moment from 'moment'
import { Swatch } from 'components/Swatch/Swatch'

const DEFAULT_HIGHWAY_HAZARD = { hazard: DEFAULT_HIGHWAY_HAZARD_VALUE, date: null }

export const HighwayHazard = ({ update, compressed }) => {
    const dateFormat = useDateFormat()
    const content = useContent()
    const [hazards, setHazards] = useState(content.hazards ? content.hazards.sort((a, b) => a.date - b.date) : [])
    const [draftHazards, setDraftHazards] = useState([])

    useEffect(() => {
        setHazards(content.hazards ? content.hazards?.sort((a, b) => a.date - b.date) : [])
    }, [content.hazards])

    const handleAddNewHighwayHazard = () => {
        const newDraftHazards = [...draftHazards, DEFAULT_HIGHWAY_HAZARD]
        setDraftHazards(newDraftHazards)
    }

    const handleRemove = (index) => {
        if (index >= hazards.length) {
            // it's a draft hazard
            const newDraftHazards = draftHazards.filter((hazard, i) => i !== index - hazards.length)
            setDraftHazards(newDraftHazards)
        } else {
            // it's a hazard
            const newhazards = hazards.filter((hazard, i) => i !== index)
            setHazards(newhazards)

            const updatedProduct = {
                ...content,
                hazards: newhazards.filter((hazard) => hazard.date !== null),
            }
            update(updatedProduct)
        }
    }

    const handleHazardChange = (value, index) => {
        if (index >= hazards.length) {
            // it's a draft hazard
            const newDraftHazards = draftHazards.map((hazard, i) => {
                return i === index - hazards.length ? { ...hazard, hazard: value } : hazard
            })
            setDraftHazards(newDraftHazards)
        } else {
            // it's a hazard
            const newhazards = hazards.map((hazard, i) => {
                return i === index ? { ...hazard, hazard: value } : hazard
            })
            setHazards(newhazards)

            const updatedProduct = {
                ...content,
                hazards: newhazards,
            }
            update(updatedProduct)
        }
    }

    const handleDateChange = (date, index) => {
        let newHazards = []
        if (index >= hazards.length) {
            // it's a draft hazard that is becoming a hazard
            const newDraftHazards = draftHazards.filter((hazard, i) => i !== index - hazards.length)
            setDraftHazards(newDraftHazards)

            newHazards = [...hazards, { ...draftHazards[index - hazards.length], date: date.toISOString() }].sort(
                (a, b) => {
                    if (a.date === null) return 1
                    if (b.date === null) return -1
                    return Date.parse(a.date) - Date.parse(b.date)
                }
            )
            setHazards(newHazards)
        } else {
            // it's a hazard
            newHazards = hazards
                .map((hazard, i) => {
                    return i === index ? { ...hazard, date: date.toISOString() } : hazard
                })
                // sort by date except for null dates should be at the bottom
                .sort((a, b) => {
                    if (a.date === null) return 1
                    if (b.date === null) return -1
                    return Date.parse(a.date) - Date.parse(b.date)
                })
            setHazards(newHazards)
        }

        // get which index is the new date and set it to be active
        const newIndex = newHazards.findIndex((hazard) => hazard.date === date.toISOString())
        const newActiveElement = document.getElementsByClassName('ant-picker-input')[newIndex]?.querySelector('input')
        newActiveElement?.focus()

        // update the product
        const updatedProduct = {
            ...content,
            hazards: newHazards.filter((hazard) => hazard.date !== null),
        }
        update(updatedProduct)
    }

    return (
        <>
            <EditScreenLayout showDaySelector={false} compressed={compressed}>
                <form style={styles.wrapper}>
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Card title={<FormattedMessage {...messages.highwayHazards} />}>
                            {[...hazards, ...draftHazards].map((hazard, index) => {
                                return (
                                    <Row gutter={16} key={index} style={styles.hazardCard}>
                                        <Col span={8}>
                                            <Form.Item
                                                label={<FormattedMessage {...messages.highwayHazards} />}
                                                style={styles.hazardField}
                                            >
                                                <Select
                                                    value={hazard.hazard}
                                                    style={{ width: 120 }}
                                                    onChange={(val) => handleHazardChange(val, index)}
                                                >
                                                    {[...HIGHWAY_HAZARD_OPTIONS.entries()].map(([rating, colour]) => {
                                                        return (
                                                            <Select.Option value={rating} key={rating}>
                                                                <Swatch colour={colour} />
                                                                <FormattedMessage
                                                                    {...messages[`highwayHazard.${rating}`]}
                                                                />
                                                            </Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={14} justify="flex-start">
                                            <Row justify="flex-start">
                                                <Form.Item
                                                    label={<FormattedMessage {...messages.highwayHazardDate} />}
                                                    style={styles.hazardField}
                                                >
                                                    <DatePicker
                                                        showTime
                                                        allowClear={false}
                                                        minuteStep={15}
                                                        value={hazard.date ? moment(hazard.date) : null}
                                                        format={dateFormat + '   HH:mm'}
                                                        onOk={(val) => handleDateChange(val, index)}
                                                        style={{ width: 'auto' }}
                                                        status={hazard.date ? 'success' : 'error'}
                                                    />
                                                </Form.Item>
                                                {hazard.date ? null : (
                                                    <p style={styles.dateWarning}>
                                                        <FormattedMessage {...messages.highwayHazardDateWarning} />
                                                    </p>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col span={2} style={styles.buttonOnRight}>
                                            <DeleteOutlined onClick={() => handleRemove(index)} />
                                        </Col>
                                    </Row>
                                )
                            })}
                            <Row>
                                <Button onClick={handleAddNewHighwayHazard} type="primary">
                                    <FormattedMessage {...messages.addNewHighwayHazard} />
                                </Button>
                            </Row>
                        </Card>
                    </Space>
                </form>
            </EditScreenLayout>
        </>
    )
}

const styles = {
    wrapper: {
        margin: 'var(--s0)',
    },
    buttonOnRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    hazardCard: {
        margin: 'var(--s0)',
        marginBottom: 'var(--s0)',
        padding: 'var(--s0)',
        border: '1px solid var(--grey-light)',
        borderRadius: 'var(--s-1)',
    },
    hazardField: {
        marginBottom: 0,
    },
    dateWarning: {
        color: 'var(--red)',
        margin: 0,
        marginLeft: 'var(--s0)',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
}
