import create from 'zustand'
import produce from 'immer'

import { createEmptyAvalancheForecast } from 'utils/AvalancheForecast'
import { filterAndSort } from 'utils/Product'
import { getOrderOfReviewStatus } from 'utils/ForecastContent'

const AvalancheForecastStore = create((set, get) => ({
    avalancheForecasts: [],
    filteredAvalancheForecasts: [],
    filters: ['draft'],
    polygons: [],
    editingAvalancheForecast: false,
    activeAvalancheForecastId: null,
    draftAvalancheForecast: createEmptyAvalancheForecast(),
    updateDraftAvalancheForecast: (draftAvalancheForecast) => {
        set({ draftAvalancheForecast })
    },
    resetDraftAvalancheForecast: () => {
        set({ draftAvalancheForecast: createEmptyAvalancheForecast() })
    },
    updateAvalancheForecasts: (avalancheForecasts) => {
        const updatedList = filterAndSort(get().avalancheForecasts, 'status', get().filters)
        set({ avalancheForecasts, filteredAvalancheForecasts: updatedList })
    },
    updateForecast: (forecast) => {
        set(
            produce((draft) => {
                for (let l in draft.avalancheForecasts) {
                    if (draft.avalancheForecasts[l].id == forecast.id) {
                        draft.avalancheForecasts[l] = forecast
                    }
                }
            })
        )
    },
    addFilter: (filter) => {
        set({ filters: [filter] })
        // run side effects
        const updatedList = filterAndSort(get().avalancheForecasts, 'status', get().filters)

        set({ filteredAvalancheForecasts: updatedList })
    },
    // We aren't removing filters in avalanche forecasts
    removeFilter: () => {},
    updatePolygons: (polygons) => {
        set({ polygons })
    },
    setActiveAvalancheForecastId: (id) => {
        set({ activeAvalancheForecastId: id })
    },
    clearActiveAvalancheForecastId: () => {
        set({ activeAvalancheForecastId: null, editingAvalancheForecast: false })
    },
    setEditingAvalancheForecast: (editingAvalancheForecast) => {
        set({ editingAvalancheForecast })
    },
}))

export const useAvalancheForecasts = () => AvalancheForecastStore((state) => state.avalancheForecasts)
export const useUpdateAvalancheForecasts = () => AvalancheForecastStore((state) => state.updateAvalancheForecasts)
export const useUpdateForecast = () => AvalancheForecastStore((state) => state.updateForecast)
export const useFilters = () => AvalancheForecastStore((state) => state.filters)
export const useAddFilter = () => AvalancheForecastStore((state) => state.addFilter)
export const useRemoveFilter = () => AvalancheForecastStore((state) => state.removeFilter)
export const useFilteredAvalancheForecasts = () => AvalancheForecastStore((state) => state.filteredAvalancheForecasts)
export const usePolygons = () => AvalancheForecastStore((state) => state.polygons)
export const useUpdatePolygons = () => AvalancheForecastStore((state) => state.updatePolygons)
export const useActiveAvalancheForecast = () =>
    AvalancheForecastStore((state) => {
        if (state.activeAvalancheForecastId === 'draft') {
            return state.draftAvalancheForecast
        }
        return state.avalancheForecasts.find(
            (avalancheForecast) => avalancheForecast.id === state.activeAvalancheForecastId
        )
    })
export const useSetActiveAvalancheForecastId = () =>
    AvalancheForecastStore((state) => state.setActiveAvalancheForecastId)
export const useClearActiveAvalancheForecastId = () =>
    AvalancheForecastStore((state) => state.clearActiveAvalancheForecastId)
export const useEditingAvalancheForecast = () => AvalancheForecastStore((state) => state.editingAvalancheForecast)
export const useSetEditingAvalancheForecast = () => AvalancheForecastStore((state) => state.setEditingAvalancheForecast)
export const useDraftAvalancheForecast = () => AvalancheForecastStore((state) => state.draftAvalancheForecast)
export const useUpdateDraftAvalancheForecast = () =>
    AvalancheForecastStore((state) => state.updateDraftAvalancheForecast)
export const useResetDraftAvalancheForecast = () => AvalancheForecastStore((state) => state.resetDraftAvalancheForecast)
export const useAssignedPolygons = () =>
    AvalancheForecastStore((state) => {
        return state.filteredAvalancheForecasts.map((avalancheForecast) => {
            return {
                id: avalancheForecast.id,
                polygons: avalancheForecast.polygons,
            }
        })
    })
export const useUnassignedPolygons = () =>
    AvalancheForecastStore((state) => {
        const { polygons, filteredAvalancheForecasts, draftAvalancheForecast } = state
        let unassignedCount = -1
        if (polygons.features && polygons.features.length > 0) {
            const allPolygons = polygons.features.map((feature) => feature.properties.id)
            const assignedPolygons = [...filteredAvalancheForecasts, draftAvalancheForecast]
                .map((avalancheForecast) => {
                    return avalancheForecast.polygons
                })
                .flat()
            unassignedCount = [...allPolygons].filter((polygon) => !assignedPolygons.includes(polygon)).length
        }

        return unassignedCount
    })
