import React, { useEffect, useCallback } from 'react'

import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { FILTER_OPTIONS } from 'utils/Product'
import { fetchAvalancheForecasts, putAvalancheForecast, cloneAllAvalancheForecasts } from 'services/AvalancheForecast'
import { fetchPolygons } from 'services/Polygons'
import messages from 'services/intl/messageDefinitions'
import { useLocale } from 'stores/UserStore'
import {
    useUpdateAvalancheForecasts,
    useUpdateForecast,
    useAvalancheForecasts,
    useFilteredAvalancheForecasts,
    useActiveAvalancheForecast,
    useClearActiveAvalancheForecastId,
    useSetActiveAvalancheForecastId,
    useSetEditingAvalancheForecast,
    useResetDraftAvalancheForecast,
    useAddFilter,
    usePolygons,
    useRemoveFilter,
    useFilters,
    useUpdatePolygons,
    useEditingAvalancheForecast,
    useDraftAvalancheForecast,
    useUpdateDraftAvalancheForecast,
    useAssignedPolygons,
    useUnassignedPolygons,
} from 'stores/AvalancheForecastStore'
import { Map, WithMapReady } from 'components/Map/Map'
import { Sidebar } from 'components/Sidebar/Sidebar'
import { Details } from 'components/AvalancheForecast/Setup/Details'
import { Summary } from 'components/AvalancheForecast/Setup/Summary'
import { CreateForm } from 'components/AvalancheForecast/Setup/CreateForm'
import { MultiProductPolygon } from 'components/Map/MultiProductPolygon'
import { TwoColLayoutPrimaryRight } from 'components/TwoColumnLayout/TwoColLayoutPrimaryRight'
import { UnassignedPolygons } from 'components/Map/UnassignedPolygons'
import { MapStatusBar } from 'components/MapStatusBar/MapStatusBar'
import { ProductStatusBarText } from 'components/Product/ProductStatusBarText'
import { DangerRatingIconSet } from 'components/Map/DangerRatingIconSet'
import { CloneAllButton } from 'components/Product/CloneAllButton'
import { Accessibility } from 'components/Accessibility'
import { MultipleProductsTable } from 'components/Map/Accessibility/MultipleProductsTable'

export const AvalancheForecast = () => {
    const [queryParams, setQueryParams] = useSearchParams()
    const locale = useLocale()
    const updateAvalancheForecasts = useUpdateAvalancheForecasts()
    const updatePolygons = useUpdatePolygons()
    const polygons = usePolygons()
    const avalancheForecast = useAvalancheForecasts()
    const filters = useFilters()
    const addFilter = useAddFilter()
    const setActiveAvalancheForecastId = useSetActiveAvalancheForecastId()
    const activeAvalancheForecast = useActiveAvalancheForecast()
    const unassignedCount = useUnassignedPolygons()
    const queryID = queryParams.get('focus') || ''
    const disableSetupButton = filters[0] !== 'draft'
    const intl = useIntl()

    useEffect(() => {
        document.title = intl.formatMessage(messages.avalancheForecast)
    }, [])

    const getForecasts = useCallback(async () => {
        const response = await fetchAvalancheForecasts(locale)
        updateAvalancheForecasts(response.data)
    }, [])

    const getPolygons = useCallback(async () => {
        const response = await fetchPolygons(locale)
        updatePolygons(response.data)
    }, [])

    useEffect(() => {
        // Fetch layers and polygons on page load
        getForecasts()
        getPolygons()
    }, [])

    useEffect(() => {
        if (queryID) {
            setActiveAvalancheForecastId(queryID)
        }
        filters.forEach((filter) => {
            // default filter
            addFilter(filter)
        })
        if (activeAvalancheForecast && activeAvalancheForecast.id === queryID) {
            addFilter(activeAvalancheForecast.status)
        }
    }, [avalancheForecast])

    const standardA11yComponent = (
        <>
            <Map locale={locale}>
                <WithMapReady>
                    {polygons && polygons.features && (
                        <>
                            <MultiProductPolygon
                                useEditing={useEditingAvalancheForecast}
                                useActiveItem={useActiveAvalancheForecast}
                                useUpdateItem={useUpdateForecast}
                                apiCall={putAvalancheForecast}
                                polygons={polygons}
                                useDraftItem={useDraftAvalancheForecast}
                                useUpdateDraftItem={useUpdateDraftAvalancheForecast}
                                useFilteredItems={useFilteredAvalancheForecasts}
                                useAssignedPolygons={useAssignedPolygons}
                                productSource="avalancheForecast"
                            />
                            <DangerRatingIconSet />
                        </>
                    )}
                </WithMapReady>
            </Map>
            <UnassignedPolygons unassignedCount={unassignedCount} />
            {activeAvalancheForecast && (
                <MapStatusBar
                    product={activeAvalancheForecast}
                    text={<ProductStatusBarText product={activeAvalancheForecast} />}
                />
            )}
        </>
    )
    const enhancedA11yComponent = polygons && polygons.features && (
        <MultipleProductsTable
            unassignedCount={unassignedCount}
            useProducts={useFilteredAvalancheForecasts}
            useEditing={useEditingAvalancheForecast}
            useActiveItem={useActiveAvalancheForecast}
            useUpdateItem={useUpdateForecast}
            putRequest={putAvalancheForecast}
            polygons={polygons}
            useDraftItem={useDraftAvalancheForecast}
            useUpdateDraftItem={useUpdateDraftAvalancheForecast}
        />
    )

    return (
        <TwoColLayoutPrimaryRight
            left={
                <Accessibility
                    standardA11yComponent={standardA11yComponent}
                    enhancedA11yComponent={enhancedA11yComponent}
                />
            }
            right={
                <Sidebar
                    title={'avalancheForecast'}
                    emptyMessage={'noAvalancheForecastsFound'}
                    Details={Details}
                    Summary={Summary}
                    dataProvider={useFilteredAvalancheForecasts}
                    filterProvider={useFilters}
                    listData={avalancheForecast}
                    filterOptions={FILTER_OPTIONS}
                    activeFilters={filters}
                    useAddFilter={useAddFilter}
                    useRemoveFilter={useRemoveFilter}
                    useClearActiveID={useClearActiveAvalancheForecastId}
                    createLabel={'createAvalancheForecast'}
                    disableSetupButton={disableSetupButton}
                    useSetActiveId={useSetActiveAvalancheForecastId}
                    useSetEditing={useSetEditingAvalancheForecast}
                    useResetDraft={useResetDraftAvalancheForecast}
                    CreateForm={CreateForm}
                    getData={getForecasts}
                    useActiveItem={useActiveAvalancheForecast}
                    radioStyleFiltering={true}
                    buttons={
                        <CloneAllButton
                            getData={getForecasts}
                            useFilters={useFilters}
                            useAddFilter={useAddFilter}
                            cloneAllApiCall={cloneAllAvalancheForecasts}
                            buttonId={'cloneAllButton'}
                        />
                    }
                ></Sidebar>
            }
        />
    )
}
