export const MENU_ITEMS = {
    weatherSummary: {
        progressPortion: 15,
    },
    snowpackSummary: {
        progressPortion: 15,
    },
    avalancheSummary: {
        progressPortion: 15,
    },
    avalancheProblems: {
        progressPortion: 20,
    },
    dangerRatings: {
        progressPortion: 5,
    },
    confidence: {
        progressPortion: 5,
    },
    media: {
        progressPortion: 5,
    },
    communications: {
        progressPortion: 10,
    },
    review: {
        progressPortion: 10,
    },
}

export const DANGER_RATING_OPTIONS = [
    {
        label: 'extreme',
        value: 'extreme',
        icon: 'extreme',
    },
    {
        label: 'high',
        value: 'high',
        icon: 'high',
    },
    {
        label: 'considerable',
        value: 'considerable',
        icon: 'considerable',
    },
    {
        label: 'moderate',
        value: 'moderate',
        icon: 'moderate',
    },
    {
        label: 'low',
        value: 'low',
        icon: 'low',
    },
    {
        label: 'noRating',
        value: 'noRating',
        icon: 'noRating',
    },
    {
        label: 'spring',
        value: 'spring',
        icon: 'spring',
    },
    {
        label: 'earlySeason',
        value: 'earlySeason',
        icon: 'earlySeason',
    },
    {
        label: 'noForecast',
        value: 'noForecast',
        icon: 'noForecast',
    },
]

export const REVIEW_STATUS_OPTIONS = [
    {
        label: 'highPriority',
        value: 'highPriority',
        colour: '#FF0000',
        order: 1,
    },
    {
        label: 'lowPriority',
        value: 'lowPriority',
        colour: '#FFFF00',
        order: 2,
        textColour: '#000000',
    },
    {
        label: 'notRequired',
        value: 'notRequired',
        colour: '',
        order: 4,
    },
    {
        label: 'reviewed',
        value: 'reviewed',
        colour: '#00FF00',
        order: 3,
        textColour: '#000000',
    },
]

export const getOrderOfReviewStatus = (status) => {
    return status ? REVIEW_STATUS_OPTIONS.find((option) => option.value === status)?.order : 4
}
export const getColourOfReviewStatus = (status) => {
    return status ? REVIEW_STATUS_OPTIONS.find((option) => option.value === status)?.colour : ''
}

export const getReviewStatusTextColour = (status) => {
    return status ? REVIEW_STATUS_OPTIONS.find((option) => option.value === status)?.textColour : ''
}

export const progressToPercent = (progress) => {
    return progress
        ? progress.reduce((sum, item) => {
              return sum + (item.complete ? MENU_ITEMS[item.element].progressPortion : 0)
          }, 0)
        : 0
}
