import create from 'zustand'
import produce from 'immer'

import { createEmptyProduct } from 'utils/Product'
import { filterAndSort, unassignedCount } from 'utils/Product'

// type is 'regionaldiscussion', 'avalancheforecast', 'specialproduct', 'highwayforecast', 'highwaydiscussion', or 'mitigation'
export const createStoreForProduct = (type) => {
    const store = ProductStore(type)

    return {
        useProducts: () => store((state) => state.products),
        useUpdateProducts: () => store((state) => state.updateProducts),
        useUpdateProduct: () => store((state) => state.updateProduct),
        useFilters: () => store((state) => state.filters),
        useAddFilter: () => store((state) => state.addFilter),
        useRemoveFilter: () => store((state) => state.removeFilter),
        useFilteredProducts: () => store((state) => state.filteredProducts),
        usePolygons: () => store((state) => state.polygons),
        useUpdatePolygons: () => store((state) => state.updatePolygons),
        useUnassignedPolygons: () =>
            store((state) => {
                const { polygons, filteredProducts, draftProduct } = state
                const count = unassignedCount(polygons, filteredProducts, draftProduct)
                return count
            }),
        useActiveProduct: () =>
            store((state) => {
                if (state.activeProductId === 'draft') {
                    return state.draftProduct
                }
                return state.products.find((product) => product.id === state.activeProductId)
            }),
        useSetActiveProductId: () => store((state) => state.setActiveProductId),
        useClearActiveProductId: () => store((state) => state.clearActiveProductId),
        useEditingProductSetup: () => store((state) => state.editingProductSetup),
        useSetEditingProductSetup: () => store((state) => state.setEditingProductSetup),
        useEditingProductContent: () => store((state) => state.editingProductContent),
        useSetEditingProductContent: () => store((state) => state.setEditingProductContent),
        useDraftProduct: () => store((state) => state.draftProduct),
        useUpdateDraftProduct: () => store((state) => state.updateDraftProduct),
        useResetDraftProduct: () => store((state) => state.resetDraftProduct),
        useAssignedPolygons: () =>
            store((state) => {
                return state.filteredProducts.map((product) => {
                    return {
                        id: product.id,
                        polygons: product.polygons,
                    }
                })
            }),
    }
}

const ProductStore = (type) =>
    create((set, get) => ({
        products: [],
        filteredProducts: [],
        filters: ['draft'],
        polygons: [],
        editingProductSetup: false,
        editingProductContent: false,
        activeProductId: null,
        draftProduct: createEmptyProduct(type),
        updateDraftProduct: (draftProduct) => {
            set({ draftProduct })
        },
        resetDraftProduct: () => {
            set({ draftProduct: createEmptyProduct(type) })
        },
        updateProducts: (products) => {
            const updatedList = filterAndSort(get().products, 'status', get().filters)
            set({ products, filteredProducts: updatedList })
        },
        updateProduct: (product) => {
            set(
                produce((draft) => {
                    for (let l in draft.products) {
                        if (draft.products[l].id === product.id) {
                            draft.products[l] = product
                        }
                    }
                })
            )
        },
        addFilter: (filter) => {
            set({ filters: [filter] })
            // run side effects
            const filteredProducts = filterAndSort(get().products, 'status', get().filters)
            set({ filteredProducts })
        },
        // We aren't removing filters in these products
        removeFilter: () => {},
        updatePolygons: (polygons) => {
            set({ polygons })
        },
        setActiveProductId: (id) => {
            set({ activeProductId: id })
        },
        clearActiveProductId: () => {
            set({ activeProductId: null, editingProduct: false })
        },
        setEditingProductSetup: (editingProductSetup) => {
            set({ editingProductSetup })
        },
        setEditingProductContent: (editingProductContent) => {
            set({ editingProductContent })
        },
    }))
