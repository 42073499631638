import React from 'react'

import { FormattedMessage } from 'react-intl'

import { truncate } from 'utils/String'

export const WeakLayerTreeNode = ({ name, type }) => {
    return (
        <div className="weaklayer-tree-node" style={{ ...styles.layerNode, borderLeft: `3px solid transparent` }}>
            <p style={styles.layerNodeName}>{truncate(name, 30)}</p>
            <p style={styles.layerNodeType}>
                <FormattedMessage id={type} defaultMessage="" />
            </p>
        </div>
    )
}

const styles = {
    layerNode: {
        // background: 'var(--grey-lighter)',
        height: '100%',
        padding: '5px 8px',
    },
    layerNodeName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '-1px',
        fontSize: '12px',
    },
    layerNodeType: {
        color: 'var(--grey)',
        marginBottom: '0px',
        fontSize: '12px',
    },
}
