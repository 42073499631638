import React, { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import './Accordion.css'
import { Drawer } from 'antd'

export const Accordion = ({
    summary,
    details,
    setCoverVisibility,
    coverVisible,
    data,
    useSetActiveId,
    useActiveItem,
    onClose,
}) => {
    const [queryParams, setQueryParams] = useSearchParams()
    const setActiveId = useSetActiveId()
    const activeItem = useActiveItem()
    const queryID = queryParams.get('focus') || ''
    const didMount = useRef(false)

    const showDrawer = () => {
        setCoverVisibility(true)
    }

    const onCloseHandler = () => {
        setCoverVisibility(false)
        queryParams.delete('focus')
        setQueryParams(queryParams)
        onClose()
    }

    useEffect(() => {
        if (!coverVisible && !activeItem && didMount.current) {
            onCloseHandler()
        }
    }, [coverVisible, activeItem])

    useEffect(() => {
        if (data.id == queryID) {
            setActiveId(queryID)
            showDrawer()
            didMount.current = true
        }
    }, [queryParams])

    useEffect(() => {
        if (activeItem) {
            setQueryParams({ focus: activeItem.id })
        }
    }, [activeItem])

    return (
        <>
            <div style={{ width: '100%', cursor: 'pointer' }} onClick={showDrawer}>
                {summary}
            </div>
            <Drawer
                // ClasName added to be referenced in the AspectElevation to get parentElement coordinates
                className="weakLayersDrawer"
                contentWrapperStyle={styles.drawer}
                closable={true}
                mask={false}
                placement="right"
                onClose={onCloseHandler}
                visible={activeItem && activeItem.id === data.id}
            >
                {details}
                {/* <button onClick={onClose}>Close</button> */}
            </Drawer>
        </>
    )
}

const styles = {
    drawer: {
        width: '36vw',
        marginTop: '2px',
        paddingTop: '0px',
        borderLeft: '1px solid var(--grey-light)',
    },
}
