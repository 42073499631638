import React, { useState, useCallback } from 'react'

import { FormattedMessage } from 'react-intl'
import { Button, Modal } from 'antd'

import { useLocale } from 'stores/UserStore'
import { useSendNotification } from 'utils/Notifications'
import messages from 'services/intl/messageDefinitions'

export const CloneAllButton = ({ getData, useFilters, useAddFilter, cloneAllApiCall, buttonId }) => {
    const [modalVisible, setModalVisibilty] = useState(false)
    const locale = useLocale()
    const filters = useFilters()
    const addFilter = useAddFilter()
    const sendNotification = useSendNotification()
    const disabled = filters[0] !== 'live'

    const cloneAll = useCallback(async () => {
        await cloneAllApiCall(locale)
        sendNotification(<FormattedMessage {...messages.cloneAllSuccess} />)
        getData()
        addFilter('draft')
    }, [addFilter, cloneAllApiCall, getData, locale, sendNotification])

    const handleCloneAll = () => {
        cloneAll()
        setModalVisibilty(false)
    }

    const confirmCloneAllModal = (
        <Modal
            title={<FormattedMessage {...messages.warning} />}
            visible={modalVisible}
            onOk={() => handleCloneAll()}
            onCancel={() => setModalVisibilty(false)}
            okText={<FormattedMessage {...messages.cloneAllYes} />}
            cancelText={<FormattedMessage {...messages.cancel} />}
            data-test={'deleteModal'}
        >
            <FormattedMessage {...messages.cloneAllWarning} />
        </Modal>
    )

    return (
        <>
            <Button
                disabled={disabled}
                onClick={() => setModalVisibilty(true)}
                type="primary"
                data-test={'cloneAllButton'}
            >
                <FormattedMessage {...messages[buttonId]} />
            </Button>
            {confirmCloneAllModal}
        </>
    )
}
