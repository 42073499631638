import { Select as AntDesignSelect } from 'antd'
import { useIntl } from 'react-intl'

import { Accessibility } from 'components/Accessibility'
import './Select.css'

export const Select = (props) => {
    const intl = useIntl()
    const standardA11yComponent = <AntDesignSelect {...props}>{props.children}</AntDesignSelect>
    // Adds a default, non-selectable option to the top of the list
    const enhancedA11yComponent = (
        <select {...props}>
            <option disabled selected value>
                {intl.formatMessage({
                    id: 'enhancedA11yModeSelectDefault',
                    defaultMessage: 'Choose an option…',
                    description: 'Enhanced a11y mode select dropdown default',
                })}
            </option>
            {props.children}
        </select>
    )

    return <Accessibility standardA11yComponent={standardA11yComponent} enhancedA11yComponent={enhancedA11yComponent} />
}

const Option = (props) => {
    const standardA11yComponent = <AntDesignSelect.Option {...props}>{props.children}</AntDesignSelect.Option>
    const enhancedA11yComponent = <option {...props}>{props.children}</option>

    return <Accessibility standardA11yComponent={standardA11yComponent} enhancedA11yComponent={enhancedA11yComponent} />
}

Select.Option = Option // Allows `<Select.Option>` as in ant-design
