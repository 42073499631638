import React, { useEffect, useState, useCallback, useMemo } from 'react'

import { useIntl } from 'react-intl'

import { useMap } from 'components/Map/Map'
import { useFilteredAvalancheForecasts, usePolygons } from 'stores/AvalancheForecastStore'
import { useDateFormat } from 'stores/UserStore'
import { Icon } from 'components/Map/Icon'
import { formatDate } from 'utils/Date'
import { pickTextColorBasedOnBgColor, shadeColor } from 'utils/Color'
import messages from 'services/intl/messageDefinitions'

const ZOOM_CUTOFF = 5.5 // Hide items when zoomed out below this threshold

export const DangerRatingIconSet = () => {
    const filteredAvalancheForecasts = useFilteredAvalancheForecasts()
    const [forecasts, setForecasts] = useState([])
    const map = useMap()
    const [show, setShow] = useState(map.getZoom() > ZOOM_CUTOFF)
    const source = 'labels'
    const storeFormat = useDateFormat()
    const polygonsGeoJson = usePolygons()
    const intl = useIntl()

    const zoomHandler = useCallback(() => {
        setShow(map.getZoom() > ZOOM_CUTOFF)
    }, [map])

    useEffect(() => {
        // Add listeners only once to prevent repeated calls
        map.on('zoom', zoomHandler)

        return () => {
            map.off('zoom', zoomHandler)
        }
    }, [map, zoomHandler])

    useEffect(() => {
        const mapableForecast = getForecastWithPolygons(filteredAvalancheForecasts)
        setForecasts(mapableForecast)
    }, [filteredAvalancheForecasts])

    const getForecastWithPolygons = (list) => {
        return list.filter((item) => {
            return item.polygons.length > 0 ? true : false
        })
    }

    // TODO: I think we should be able to remove this with the centroid update on the backend
    const iconLocation = (forecast) => {
        // If no centroid, use the first polygon
        const { polygons } = forecast
        const somePolygonId = polygons[0]

        let someCoords = polygonsGeoJson.features.find((polygon) => polygon.properties.id === somePolygonId).geometry
            .coordinates[0][0]

        // This is a hack to manage CAIC polygons which seem to be coming in a different format. See comment above this will all be removed eventually.
        if (someCoords.length > 2) {
            someCoords = someCoords[0]
        }

        return someCoords
    }

    const iconInjectedForecasts = useMemo(() => {
        return forecasts.map((forecast) => {
            const coordinates = iconLocation(forecast)

            return {
                ...forecast,
                icon: {
                    // TODO: I think we should be able to remove the ternary with the centroid update on the backend
                    longitude: forecast.centroid ? forecast.centroid[0] : coordinates[0],
                    latitude: forecast.centroid ? forecast.centroid[1] : coordinates[1],
                },
            }
        })
    }, [forecasts, polygonsGeoJson])

    // TODO: Icon location doesn't change but text does
    const createLabelFeatureCollection = useCallback(() => {
        return {
            type: 'FeatureCollection',
            features: iconInjectedForecasts.map((forecast) => {
                const formattedValue = formatDate(forecast.expiryDateTime, storeFormat)
                const shadedBackgroundColour = shadeColor(forecast.colour, -30) // Assume slightly darker
                const textColour = pickTextColorBasedOnBgColor(shadedBackgroundColour, '#ffffff', '#000000')

                return {
                    type: 'Feature',
                    properties: {
                        description: intl.formatMessage({ ...messages.expiryAbbreviation }, { date: formattedValue }),
                        textColour,
                        strokeColour: forecast.colour,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [forecast.icon.longitude, forecast.icon.latitude],
                    },
                }
            }),
        }
    }, [iconInjectedForecasts, intl, storeFormat])

    useEffect(() => {
        if (map.getSource(source)) {
            map.getSource(source).setData(createLabelFeatureCollection())
        }
    }, [map, forecasts, createLabelFeatureCollection])

    useEffect(() => {
        if (!map.getSource(source)) {
            map.addSource(source, {
                type: 'geojson',
                data: createLabelFeatureCollection(),
            })
        }

        if (!map.getLayer(source)) {
            map.addLayer({
                id: source,
                source,
                type: 'symbol',
                layout: {
                    'text-field': ['get', 'description'],
                    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                    'text-radial-offset': 2.25,
                    'text-justify': 'auto',
                    'text-size': 12,
                },
                paint: {
                    'text-color': ['get', 'textColour'],
                    'text-halo-color': ['get', 'strokeColour'],
                    'text-halo-width': 1,
                    'text-halo-blur': 1,
                },
                minzoom: ZOOM_CUTOFF,
            })
        }
    }, [])

    return iconInjectedForecasts.map((forecast) => {
        const { id, name, icon, dangerRatings } = forecast
        const { longitude, latitude } = icon

        return (
            <Icon
                key={id}
                name={name}
                longitude={longitude}
                latitude={latitude}
                show={show}
                dangerRatings={dangerRatings[1]}
            />
        )
    })
}
