import useSWRImmutable from 'swr/immutable'

import { useLocale } from 'stores/UserStore'
import { fetchPolylines } from 'services/Polylines'
import { HighwayDiscussionStore } from 'stores/HighwayDiscussionStore'
import { unassignedCount } from 'utils/Product'

export const usePolylines = () => {
    const locale = useLocale()

    const key = ['/geospatial/hwylines' + locale]

    const { data, isLoading } = useSWRImmutable(key, () => fetchPolylines(locale))

    if (isLoading) {
        return null
    }

    return data.data
}

export const useUnassignedPolylines = () => {
    const polylines = usePolylines()

    const filteredProducts = HighwayDiscussionStore.useFilteredProducts()
    const draftProduct = HighwayDiscussionStore.useDraftProduct()

    if (!polylines || !filteredProducts || !draftProduct) {
        return -1
    }

    const count = unassignedCount(polylines, filteredProducts, draftProduct)
    return count
}
