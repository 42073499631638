import React, { useCallback, useState } from 'react'

import { Button, Divider, Typography, Row, Col, Progress, Tag, Modal, Checkbox, Space } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import { useLocale } from 'stores/UserStore'
import {
    putHighwayForecast,
    cloneHighwayForecast,
    publishHighwayForecast,
    deleteHighwayForecast,
} from 'services/HighwayForecasts'
import { HighwayForecastStore } from 'stores/HighwayForecastStore'
import { fetchHighwayForecast } from 'services/HighwayForecasts'
import { progressToPercent, getColourOfReviewStatus, getReviewStatusTextColour, MENU_ITEMS } from 'utils/HighwayContent'
import { Stat } from 'components/Stat/Stat'
import { Date } from 'components/Date/Date'
import { PolygonList } from 'components/PolygonList/PolygonList'
import { CreateForm } from './CreateForm'
import { sendErrorNotification, useSendNotification } from 'utils/Notifications'
import { DeleteButton } from 'components/DeleteButton/DeleteButton'
import { Spacer } from 'components/VerticalSpacer/Spacer'
import messages from 'services/intl/messageDefinitions'
import { API_SUCCESS_CODES } from 'utils/Constants'
import { getDrawerDetailsWidth } from 'utils/screen'
import { Forecast as ForecastContent } from 'components/ContentSection/Forecast'
import { useLocation } from 'react-router'

export const Details = ({ item, closeAndReload, closeAndOpenCloned, showContent, viewWidth = 36 }) => {
    // External imports
    const intl = useIntl()
    const { Title } = Typography
    const { pathname } = useLocation()

    // User store data
    const locale = useLocale()

    // Store data
    const editing = HighwayForecastStore.useEditingProductSetup()
    const setEditing = HighwayForecastStore.useSetEditingProductSetup()
    const updateForecast = HighwayForecastStore.useUpdateProduct()

    // Local state
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [cloneError, setCloneError] = useState(false)

    // Others
    const sendNotification = useSendNotification()
    const detailsColWidth = getDrawerDetailsWidth(viewWidth)

    const updateHighwayForecast = useCallback(async (data, locale) => {
        const response = await putHighwayForecast(data, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            sendNotification(intl.formatMessage({ ...messages.highwayForecastSaved }, { name: data.name }))
            updateForecast(response.data)
        } else {
            sendErrorNotification(intl.formatMessage({ ...messages.highwayForecastSaveError }, { name: data.name }))
        }
    }, [])

    const cloneForecast = useCallback(async (item, locale) => {
        const response = await cloneHighwayForecast(item.id, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            closeAndOpenCloned(response.data.id)
        } else {
            if (response.data && response.data.message === 'Polygons are assigned to draft product') {
                setCloneError(true)
            } else {
                sendErrorNotification(intl.formatMessage({ ...messages.productCloneError }))
            }
        }
    }, [])

    const publish = useCallback(async (item) => {
        const response = await publishHighwayForecast(item.id, locale)
        updateForecast(response.data)
        closeAndReload()
    }, [])

    const deleteForecast = useCallback(async (id, locale) => {
        await deleteHighwayForecast(id, locale)
        sendNotification(intl.formatMessage({ ...messages.highwayForecastDeleted }, { name: item.name }))
        setDeleteModalVisible(false)
        closeAndReload()
    }, [])

    // Updates the various fields in the avalanche forecast
    // details should be in the format: [{key: <field to be changed> value: <updated values>}, {...}]
    const update = (details) => {
        const data = { ...item }
        for (let d in details) {
            const detail = details[d]
            data[detail.key] = detail.value
        }
        updateHighwayForecast(data, locale)
    }

    const toggleEdit = () => {
        setEditing(!editing)
    }

    const toggleTranslated = () => {
        update([{ key: 'isTranslated', value: !item.isTranslated }])
    }

    // Adjusts the spacing if the translated checkbox is shown. Two rows if the translated checkbox is shown.
    const infoColumnSpacing = process.env.REACT_APP_SHOW_TRANSLATION_BUTTON === 'true' ? [12, 12, 12, 12] : [9, 6, 9, 0]

    const detailsView = (
        <div style={styles.detailsBody}>
            <Title level={2} style={styles.title}>
                {item.name}
            </Title>
            <div style={styles.header}>
                <div>{item.username}</div>
                <Date label={'lastModified'} time={true} value={item.lastModifiedDateTime} />
            </div>
            <div style={styles.detailsContainer}>
                <Divider orientation="left" plain>
                    <FormattedMessage {...messages.details} />
                </Divider>
                <Row gutter={8}>
                    <Col style={styles.detailCol} span={detailsColWidth}>
                        <Stat label={'issueDateTime'} value={item.issueDateTime} type={'date'} time={true} />
                    </Col>
                    <Col style={styles.detailCol} span={detailsColWidth}>
                        <Stat label={'expiryDateTime'} value={item.expiryDateTime} type={'date'} time={true} />
                    </Col>
                    <Col span={detailsColWidth}>
                        {item.review && item.review.status && (
                            <Stat
                                label={'reviewStatus'}
                                value={
                                    <Tag
                                        color={getColourOfReviewStatus(item.review.status)}
                                        style={{ color: getReviewStatusTextColour(item.review.status) }}
                                    >
                                        <FormattedMessage {...messages[item.review.status]} />
                                    </Tag>
                                }
                            />
                        )}
                    </Col>
                    <Col span={detailsColWidth}>
                        <Stat label={'progress'} value={<Progress percent={progressToPercent(item.progress)} />} />
                    </Col>
                    {process.env.REACT_APP_SHOW_TRANSLATION_BUTTON === 'true' && (
                        <Col span={detailsColWidth} style={styles.translatedCheckboxContainer}>
                            <Checkbox
                                label={intl.formatMessage({ ...messages.translated })}
                                checked={item.isTranslated}
                                onChange={toggleTranslated}
                                data-test="translatedCheckbox"
                                style={styles.translatedCheckbox}
                                disabled={item.status !== 'draft'}
                            >
                                <FormattedMessage {...messages.translated} />
                            </Checkbox>
                        </Col>
                    )}
                </Row>
                <PolygonList
                    polygonList={item.polygons}
                    colour={item.colour}
                    usePolygons={HighwayForecastStore.usePolygons}
                />
            </div>
            {item.status === 'draft' && (
                <>
                    <Row style={styles.buttonContainer}>
                        <Button onClick={toggleEdit} data-test={'forecastEditButton'}>
                            <FormattedMessage {...messages.editSetup} />
                        </Button>
                        <Button
                            onClick={() => window.open(`/highway-forecast-content?focus=${item.id}`, '_blank')}
                            disabled={!item.userID}
                            title={intl.formatMessage({ ...messages.missingForecaster })}
                            data-test={'editContentButton'}
                            data-test-forecast={item.id}
                        >
                            <FormattedMessage {...messages.editContent} />
                        </Button>
                        <Button onClick={() => cloneForecast(item, locale)} data-test={'cloneForecastButton'}>
                            <FormattedMessage {...messages.cloneToDraft} />
                        </Button>
                        <Button type="primary" onClick={() => publish(item)}>
                            <FormattedMessage {...messages.publish} />
                        </Button>
                    </Row>
                    <DeleteButton setDeleteModalVisible={setDeleteModalVisible} buttonText={'deleteHwyForecast'} />
                </>
            )}
            {(item.status === 'completed' || item.status === 'live') && (
                <>
                    <Row style={styles.buttonContainer}>
                        <Button onClick={() => cloneForecast(item, locale)}>
                            <FormattedMessage {...messages.cloneToDraft} />
                        </Button>
                        {pathname !== '/archive' && (
                            <Button disabled>
                                <FormattedMessage {...messages.preview} />
                            </Button>
                        )}
                    </Row>
                    <DeleteButton setDeleteModalVisible={setDeleteModalVisible} buttonText={'deleteHwyForecast'} />
                </>
            )}

            <Spacer />
            {showContent && (
                <ForecastContent
                    compressed
                    colWidth={detailsColWidth}
                    fetchForecast={fetchHighwayForecast} // TODO: pass product data or fetch function
                    menuItems={MENU_ITEMS}
                    putForecast={() => {}}
                    progressToPercent={progressToPercent}
                />
            )}
        </div>
    )

    const editView = (
        <>
            <CreateForm
                toggleEdit={toggleEdit}
                editing={editing}
                update={update}
                initialValues={item}
                autoUpdate={true}
                closeAndReload={closeAndReload}
                setDeleteModalVisible={setDeleteModalVisible}
                destroyOnClose={true}
            />
        </>
    )

    let contentView = editing ? editView : detailsView

    return (
        <>
            {contentView}
            <Modal
                title={<FormattedMessage {...messages.warning} />}
                visible={deleteModalVisible}
                onOk={() => deleteForecast(item.id, locale)}
                onCancel={() => setDeleteModalVisible(false)}
                okText={<FormattedMessage {...messages.yesConfirmDeleteHighwayForecast} />}
                cancelText={<FormattedMessage {...messages.cancel} />}
                data-test={'deleteForecastModal'}
            >
                <p>
                    <FormattedMessage {...messages.deleteHighwayForecastConfirmation} values={{ name: item.name }} />
                </p>
            </Modal>
            <Modal
                title={<FormattedMessage {...messages.clonePolygonError} />}
                visible={cloneError}
                onCancel={() => setCloneError(false)}
                okText={<FormattedMessage {...messages.ok} />}
                footer={
                    <Button type="primary" onClick={() => setCloneError(false)}>
                        <FormattedMessage {...messages.ok} />
                    </Button>
                }
            >
                <p>
                    <FormattedMessage {...messages.productCloneErrorPolygons} />
                </p>
            </Modal>
        </>
    )
}

const styles = {
    detailsBody: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
    },
    title: {
        fontWeight: '400',
        flex: '0 1 auto',
    },
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'var(--s-1)',
        justifyContent: 'space-between',
        flex: '0 1 auto',
    },
    detailsContainer: {
        flex: '0 1 auto',
    },
    detailCol: {
        marginBottom: 'var(--s0)',
    },
    statusGrid: {
        marginBottom: 'var(--s0)',
    },
    buttonContainer: {
        marginTop: 'var(--s1)',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'var(--s0)',
        flex: '0 1 auto',
    },
    deleteButtonContainer: {
        marginTop: 'var(--s0)',
        flex: '1 1 auto',
    },
    deleteButton: {
        marginBottom: 'var(--s-2)',
        marginLeft: 'auto',
        alignSelf: 'flex-end',
    },
    translatedCheckboxContainer: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    translatedCheckbox: {
        marginBottom: 'var(--s0)',
    },
}
