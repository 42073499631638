import { message } from 'antd'
import { useNotificationLevel } from 'stores/UserStore'

const DURATION = 3
export const successNotification = (content, key) => {
    message.success({ content, key, duration: DURATION })
}

export const sendErrorNotification = (text) => {
    message.error(text, DURATION)
}

// This is a hook so that it can can call the useNotificationLevel hook (which in turn calls an SWR hook)
export const useSendNotification = () => {
    // Only send notification if the level is set to 'all'
    const notificationsLevel = useNotificationLevel()
    if (notificationsLevel === 'all') {
        return (message) => successNotification(message, message)
    }

    return () => {}
}
