import { Button } from 'antd'
import { useLocation } from 'react-router'
import { FormattedMessage } from 'react-intl'

import messages from 'services/intl/messageDefinitions'

export const DeleteButton = ({ setDeleteModalVisible, buttonText }) => {
    const { pathname } = useLocation()

    return (
        pathname !== '/archive' && (
            <div style={styles.container}>
                <Button
                    onClick={() => setDeleteModalVisible(true)}
                    type="primary"
                    danger
                    style={styles.deleteButton}
                    data-test={'deleteForecastButton'}
                >
                    <FormattedMessage {...messages[buttonText]} />
                </Button>
            </div>
        )
    )
}

const styles = {
    container: {
        marginTop: 'var(--s0)',
        display: 'flex',
        flex: '1 1 auto',
    },
    deleteButton: {
        marginBottom: 'var(--s-2)',
        marginLeft: 'auto',
        alignSelf: 'flex-end',
    },
}
